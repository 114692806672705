import axios from 'axios'

import React, { useContext } from 'react'

const apiCall = async (method, url, data, headertype, baseurl) => {
    let site_url = `${global.site_url}/api/user`
    if (baseurl) {
        site_url = `${global.site_url}/api/${baseurl}`
    }
    return new Promise(async (resolve, reject) => {
        let type = ''
        if (headertype && headertype === 'formdata') {
            type = 'multipart/form-data'
        } else {
            type = 'application/json'
        }
        const config = {
            headers: {
                'content-type': type,
            },
        }
        switch (method) {
            case 'post':
                try {
                    data = data ? data : {}
                    const res = await axios.post(`${site_url}/${url}`, data, config)
                    if (res.data.success == false && res.data.controlHubError == 1) {
                        console.log('control hub API disabled____________')
                        global.maintainance_mode = true
                        reject(res)
                    }
                    resolve(res)
                    break
                } catch (err) {
                    //console.log('responsode error from api', err)
                    console.log('[apiCall] ', err)
                    resolve(err)
                    break
                }
            case 'get':
                try {
                    let getUrl = site_url
                    if (url !== '') {
                        getUrl += `/${url}`
                    }
                    const res = await axios.get(getUrl, config)
                    if (res.data.success == false && res.data.controlHubError == 1) {
                        console.log('control hub API disabled____________')
                        global.maintainance_mode = true
                    }
                    resolve(res)
                    break
                } catch (err) {
                    //console.log('responsode error from api', err)
                    console.log('[apiCall] ', err)
                    resolve(err)
                    break
                }
            default:
                return null
        }
    })
}

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['authorization']
    }
}

const setIPAddress = (ipaddress) => {
    if (ipaddress) {
        axios.defaults.headers.common['ipaddress'] = ipaddress
    } else {
        delete axios.defaults.headers.common['ipaddress']
    }
}
const getAuthToken = () => {
    return localStorage.getItem('token')
        ? localStorage.getItem('token')
        : localStorage.getItem('nonLoginToken')
        ? localStorage.getItem('nonLoginToken')
        : ''
}
export { apiCall, setAuthToken, setIPAddress, getAuthToken }
