import React, { useState, useRef, useEffect, useContext } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import './ProductCard.css'
import FavouriteCheckbox from '../../atoms/FavoriteCheckbox'
import Popup from '../../organisms/Popup'
import Timer from '../../../common/timer'
import AddDispute from './AddDispute'
import Bidding from '../../molecules/Bidding/BiddingItem'
import { dateFormatFront } from '../../../common/components'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import CommonContext from '../../../context/common/commonContext'
import AuthContext from '../../../context/auth/authContext'
import { Link } from 'react-router-dom'

const ListView = (props) => {
    const [addCard, setAddCard] = React.useState(false)
    const product = props.data
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)

    const { isAuthenticated, user } = authContext
    const { setBidHistoryValue, phrase } = commonContext
    const [disputePopup, setDisputePopup] = useState(false)

    const history = useHistory()

    const openDisputePopup = () => {
        setDisputePopup(true)
    }
    const closeDisputePopup = () => {
        setDisputePopup(false)
    }

    let dashboardLink = null
    if (props.dashboard && product) {
        if (props.dashboardType == 'won' || props.dashboardType == 'buy_now') {
            dashboardLink = (
                <div>
                    <div className="mb-1">
                        <Link to={`/invoice/${product.invoice_id}`}>
                            <PrimaryButton label={phrase.view + ' ' + phrase.invoice} />
                        </Link>
                    </div>
                    <div>
                        <PrimaryButton
                            onClick={() => openDisputePopup()}
                            label={phrase.add + ' ' + phrase.dispute}
                        />
                        <AddDispute
                            open={disputePopup}
                            product={product}
                            openDisputePopup={() => openDisputePopup()}
                            closeDisputePopup={() => closeDisputePopup()}
                        />
                    </div>
                </div>
            )
        } else if (props.dashboardType == 'active') {
            dashboardLink = (
                <Link to={`/product/view/${product.id}`}>
                    <PrimaryButton label={phrase.view} />
                </Link>
            )
        }
    }

    let categories = ''
    if (product) {
        for (let i in product.multiplecategoryshow) {
            if (i == product.multiplecategoryshow.length - 1) {
                // last index
                //categories += product.multiplecategoryshow[i]
                categories += phrase[product.multiplecategoryshow[i]]
            } else {
                //categories += product.multiplecategoryshow[i] + ', '
                categories += phrase[product.multiplecategoryshow[i]] + ','
            }
        }
    }

    return (
        <>
            <div className="productCardList d-flex justify-content-between align-items-start">
                <div className="psListLt d-flex">
                    <div className="pclImg">
                        <Link to={`/product/view/${product.id}`}>
                            <img
                                src={global.site_url + '/uploads/product/' + product.avatar}
                                alt={product.title}
                            />
                        </Link>
                        {props.completed && <h4 className="aucEnded">Auction Ended</h4>}
                        {(props.dashboard &&
                            props.dashboardType != 'watchlist' &&
                            props.dashboardType != 'active') ||
                        props.completed ? null : user.isBuyer || user.is_salesrep ? (
                            <FavouriteCheckbox
                                watchlisted={product.watchlistid}
                                project_id={product.id}
                                isDashboard={props.dashboard}
                            />
                        ) : null}
                        <div className="listBidStatus">
                            {isAuthenticated && (
                                <>
                                    {product.bidtopstatus === 'outbid' && (
                                        <h4 className="productWinningStatus outbid">OUTBID</h4>
                                    )}
                                    {product.bidtopstatus === 'winner' && (
                                        <h4 className="productWinningStatus winning">WINNING</h4>
                                    )}
                                    {product.bidtopstatus === 'won' && (
                                        <h4 className="productWinningStatus won">WON</h4>
                                    )}
                                    {product.bidtopstatus === 'lost' && (
                                        <h4 className="productWinningStatus lost">LOST</h4>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <div className="listContent ">
                        <h2 className="listProdTitle">
                            <Link to={`/product/view/${product.id}`}>{product.title}</Link>
                        </h2>

                        <div className="listLotDetails">
                            <div className="gridLotDetails">
                                <p>
                                    <label>{phrase.condition}</label>
                                    {phrase[product.condition_language]}
                                </p>
                                <p>
                                    <label> {phrase.no_of_items} </label> {product.qty}
                                    {phrase.items}
                                </p>
                            </div>
                            <div className="gridLotDetails">
                                <p>
                                    <label> UPC/SKU </label>
                                    {product.upc}
                                </p>
                                <p>
                                    <label> {phrase.category} </label>
                                    {categories}
                                </p>
                            </div>
                            <div className="gridLotDetails">
                                <p>
                                    <label> {phrase.lot_size} </label>
                                    {phrase[product.lottype]} {phrase.lot}
                                </p>
                                <p>
                                    <label> {phrase.auction_type} </label>
                                    {product.auctiontype == 'live'
                                        ? phrase.live_auction
                                        : product.auctiontype == 'lineitem'
                                        ? phrase.line_item_auction
                                        : phrase.blind_auction}
                                </p>
                            </div>
                            <div className="gridLotDetails">
                                <p>
                                    <label>{phrase.manufacturer}</label>
                                    {product.manufacturer}
                                </p>
                                <p>
                                    <label>Model</label>
                                    {product.modelnumber}
                                </p>
                            </div>
                            <div className="gridLotDetails">
                                <p>
                                    <label>Size</label>
                                    {product.size}
                                </p>
                                <p>
                                    <label>{phrase.finish}</label>
                                    {product.finish}
                                </p>
                            </div>
                            <div className="gridLotDetails fr1">
                                <p>
                                    <label> {phrase.location} </label>
                                    {product.city}, {product.state}, {product.country}
                                </p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between flex-wrap">
                            {product.market_status === 'open' && !props.completed ? (
                                product.auctiontype !== 'lineitem' ? (
                                    <>
                                        {product.auctiontype == 'live' ? (
                                            <div className="listBidInfo d-flex justify-content-between align-items-center">
                                                <h6>{phrase.current_bid}:</h6>
                                                <p>
                                                    <span>
                                                        {product.currency_symbol} {product.wprice}
                                                    </span>
                                                </p>
                                            </div>
                                        ) : null}

                                        <div className="listBidInfo d-flex justify-content-between align-items-center">
                                            <h6>{phrase.time_left}:</h6>
                                            <p className="d-flex align-items-center">
                                                {product.date_closed ? (
                                                    <Timer
                                                        date_added={product.date_added}
                                                        date_closed={product.date_closed}
                                                        withText={0}
                                                    ></Timer>
                                                ) : null}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <div className="listBidInfo d-flex justify-content-between align-items-center">
                                        <h6>{phrase.time_left}:</h6>
                                        <p className="d-flex align-items-center">
                                            {product.date_closed ? (
                                                <Timer
                                                    date_added={product.date_added}
                                                    date_closed={product.date_closed}
                                                    withText={0}
                                                ></Timer>
                                            ) : null}
                                        </p>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className={`listActBtn ${props.completed && 'ended'}`}>
                    {props.dashboard ? (
                        dashboardLink
                    ) : (
                        <Link to={`/product/view/${product.id}`}>
                            <PrimaryButton label={phrase.view} btnSize="small" />
                        </Link>
                    )}
                </div>
            </div>
        </>
    )
}
export default ListView
