import React, { useState, useEffect, useContext } from 'react'
import { Button, Popover } from '@material-ui/core'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import './Bidding.css'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Timer from '../../../common/timer'
import CommonContext from '../../../context/common/commonContext'
import AlertContext from '../../../context/alert/alertContext'
import BiddingItem from './BiddingItem'
import Popup from '../../organisms/Popup'
import { dateFormatFront, handleRedirectInternal } from '../../../common/components'
import { apiCall } from '../../../common/api'
import { formatCurrency } from '../../../Utils/Functions'

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
        '& h4': {
            fontWeight: '700',
            color: '#666',
            textAlign: 'center',
        },
        '& p': {
            fontSize: '14px',
            color: '#666',
            textAlign: 'center',
        },
    },
    paper: {
        padding: theme.spacing(1),
        width: '500px',
        padding: '20px',
    },
}))

const BiddingCard = ({ phrase, product, setProduct, user }) => {
    const [bidValue, setBidValue] = useState(product.buyerPrice)
    const [anchorEl, setAnchorEl] = useState(null)
    const [bidPopup, setBidPopup] = useState(false)
    const [contactPopup, setContactPopup] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [csvBidReq, setCsvBidReq] = useState(false)
    const classes = useStyles()
    const commonContext = useContext(CommonContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const history = useHistory()
    const { setBidHistoryValue } = commonContext

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    /** Convert Bid Amount
     * @param {string} type valToFormat = Actual bid amount to formatted amount, formatToVal = Formatted amount to actual value
     */
    const convertBidAmt = (amt, type) => {
        if (type == 'valToFormat') {
            if (amt == '0') return ''
            return amt
                .toString()
                .replace(/,/g, '')
                .replace(/\D/g, '') // replace non-numeric char with empty string
                .replace(/[^\d].+/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else if (type == 'formatToVal') {
            if (amt == '') return 0
            return amt
                .toString()
                .replace(/,/g, '')
                .replace(/[^\d].+/, '')
        }
    }

    const [bidValDisplay, setBidValDisplay] = useState(
        convertBidAmt(product.buyerPrice, 'valToFormat'),
    )

    const bidInputChangeHandler = (value) => {
        setBidValDisplay(convertBidAmt(value, 'valToFormat'))
        setBidValue(convertBidAmt(value, 'formatToVal'))
    }

    const open = Boolean(anchorEl)
    let bidValid = false

    useEffect(() => {
        setBidValue(product.buyerPrice)
        setBidValDisplay(convertBidAmt(product.buyerPrice, 'valToFormat'))
    }, [product.buyerPrice])

    useEffect(() => {
        if (parseFloat(bidValue) >= parseFloat(product.next_bid)) {
            setErrorMsg('')
        }
    }, [bidValue, product])
    useEffect(() => {
        if (csvBidReq) {
            setCsvBidReq(!csvBidReq)
            popupOpen()
        }
    }, [csvBidReq])
    // runs before opening bid confirmation popup
    const popupOpen = () => {
        console.log('bid valid ? ', bidValid)
        // validate bid amount
        if (parseFloat(bidValue) < parseFloat(product.next_bid)) {
            console.log('BID Value: ', bidValue)
            console.log('NEXT BID: ', product.next_bid)
            if (product.auctiontype == 'live') {
                setErrorMsg(
                    `${phrase.parsley_greater_equal_message} ${product.currency_symbol} ${product.next_bid}`,
                )
            } else {
                // blind auction
                if (product.sprice == product.next_bid) {
                    setErrorMsg(
                        `${phrase.parsley_greater_equal_message} ${product.currency_symbol} ${product.next_bid}`,
                    )
                } else {
                    setErrorMsg(
                        `${phrase.next_bid_must_increase_by}  ${product.currency_symbol} ${product.blindIncrementVal}`,
                    )
                }
            }

            return
        }
        setErrorMsg('')
        setBidPopup(true)
    }
    const popupClose = () => {
        setBidPopup(false)
    }
    const contactPopupOpen = () => {
        setContactPopup(true)
    }
    const contactPopupClose = () => {
        setContactPopup(false)
    }
    const contactSeller = () => {
        let msg = document.getElementById('contactSellerMsg')
        if (msg.value != '') {
            contactPopupClose()
            let data = {}
            data.to_id = product.sellerid
            data.projectmsgpost = product.id
            data.message = msg.value
            data.from_id = user.id
            apiCall('post', 'savemessagefromview', data, '', 'messages')
                .then((res) => {
                    msg.value = ''
                    setAlert(phrase.message_successfully_sent, 'success')
                })
                .catch((err) => {
                    console.log(err)
                    setAlert('Something wrong happend.. Please try again.', 'error')
                })
        }
    }
    const openCsvBidFile = () => {
        document.getElementById('bid_csv').click()
    }
    const uploadCsvBidFile = (e) => {
        console.log('UPLOAD FILE EVENT: ', e)
        var formData = new FormData()
        var bidFile = document.querySelector('#bid_csv')
        if (bidFile.files.length > 0) {
            formData.append('bid_csv', bidFile.files[0])
            apiCall('post', 'upload-bid-csv', formData, 'formdata', 'product')
                .then((res) => {
                    console.log(res)
                    if (res.data.msgdata == 'success') {
                        console.log('SUCCESS')
                        let productCopy = { ...product }
                        productCopy.csvfile = res.data.csvfile
                        setProduct(productCopy)
                        setBidValue(parseFloat(res.data.bidamount))
                        setBidValDisplay(
                            convertBidAmt(parseFloat(res.data.bidamount), 'valToFormat'),
                        )
                        setCsvBidReq(true)
                    }
                })
                .catch((err) => {
                    console.log('ERROR: ', err)
                    setAlert('Something wrong happend.. Please try again.', 'error')
                })
        }
    }

    const redirectToBuynow = () => {
        handleRedirectInternal(history, `product/buynow/${product.id}`)
    }

    // top part consists of current bid & timer
    let topPart = []
    let viewHistory = null
    let enterOrMoreMsg = null
    let reserveMetMessage = null
    let submitBtn = []

    const [isBuyer, setIsBuyer] = useState(true)
    const [internalSelected, setInternalSelected] = useState(false)
    useEffect(() => {
        if (user) {
            // if user is admin, or user is seller
            // is_emp = 1, is seller
            if (user.admin != '0' || user.is_emp == '1') {
                console.log('IS BUYER:: false')
                setIsBuyer(false)
            }
        }
        if (user && product) {
            if (
                product.internal_sales &&
                product.internal_sales == '1' &&
                user.is_salesrep &&
                user.is_salesrep == '1'
            ) {
                if (product.selltype == 'all') {
                    setInternalSelected(true)
                } else if (product.selltype == 'notall' && product.selectedbuyer != '') {
                    let selectedbuyer = product.selectedbuyer.toString().split(',')
                    if (selectedbuyer.includes(user.id.toString())) {
                        setInternalSelected(true)
                    }
                }
            }
        }
    }, [user, product])

    // buynow btn
    if (
        product.market_status == 'open' &&
        product.buynow === 1 &&
        parseFloat(product.wprice) < parseFloat(product.rprice)
    ) {
        let label = phrase.buy_it_now + ' ' + product.currency_symbol + product.bprice
        submitBtn.push(
            <div className="col">
                <PrimaryButton
                    btnSize="large"
                    label={label}
                    onClick={redirectToBuynow}
                    disabled={!isBuyer && !internalSelected ? true : false}
                />
            </div>,
        )
    }

    let bidInput =
        product.market_status == 'open' ? (
            <input
                type="text"
                value={bidValDisplay}
                onChange={(e) => bidInputChangeHandler(e.target.value)}
            />
        ) : (
            <input type="number" min="0" value={bidValue} disabled />
        )

    // * product.buyerPrice includex buyer's max proxy amount
    let yourCurrentBid = product.buyerPrice > product.wprice ? product.wprice : product.buyerPrice
    let blindAuctionBidMessage = null

    // current bid can only be shown if the auction type is live
    if (product.auctiontype == 'live') {
        submitBtn.push(
            <div className="col">
                <PrimaryButton
                    btnSize="large"
                    label={phrase.submit_bid}
                    onClick={popupOpen}
                    handleClose={popupClose}
                    disabled={!isBuyer && !internalSelected ? true : false}
                />
            </div>,
        )
        topPart.push(
            <div key="currentBid" className="bidTimeCntLt">
                <label> {phrase.current_bid} </label>
                <h5>
                    {product.currency_symbol}
                    {product.wprice} &nbsp;
                    {product.currency}
                </h5>
            </div>,
        )
        viewHistory = (
            <Button className="viewBids" onClick={() => setBidHistoryValue(product.id)}>
                {' '}
                {phrase.view_all_bids}{' '}
            </Button>
        )
        if (product.market_status == 'open') {
            enterOrMoreMsg = (
                <>
                    {' '}
                    {phrase.enter} {product.currency} {product.currency_symbol}
                    {parseInt(product.next_bid)} {phrase.or_more}
                </>
            )
        }
    }
    // blind auction
    else {
        topPart.push(
            <div key="currentBid" className="bidTimeCntLt">
                <label> {phrase.your_current_bid} </label>
                <h5>
                    {product.currency_symbol}
                    {formatCurrency(yourCurrentBid)}
                    &nbsp;
                    {product.currency}
                </h5>
            </div>,
        )
        if (product.market_status == 'open') {
            // CSV Bid
            if (!product.isNotCsvBid) {
                submitBtn.push(
                    <div className="col">
                        <PrimaryButton
                            btnSize="large"
                            label={phrase.upload}
                            onClick={openCsvBidFile}
                            handleClose={popupClose}
                            disabled={!isBuyer && !internalSelected ? true : false}
                        />
                    </div>,
                )
                bidInput = (
                    <>
                        <input type="text" value={bidValDisplay} disabled />
                        <input
                            type="file"
                            id="bid_csv"
                            accept=".csv,.xls,.xlsx"
                            onChange={(e) => uploadCsvBidFile(e)}
                            hidden
                        />
                    </>
                )
            } else {
                submitBtn.push(
                    <div className="col">
                        <PrimaryButton
                            btnSize="large"
                            label={phrase.submit_bid}
                            onClick={popupOpen}
                            handleClose={popupClose}
                            disabled={!isBuyer && !internalSelected ? true : false}
                        />
                    </div>,
                )
            }

            // if this buyer has max proxy bid, add bid message
            if (product.buyerPrice > product.wprice) {
                blindAuctionBidMessage = `${phrase.your_current_bid_on_this_auction_is} ${
                    product.currency_symbol
                }${formatCurrency(product.wprice)}. ${phrase.your_autobid_of} ${
                    product.currency_symbol
                }${formatCurrency(product.buyerPrice)} ${phrase.has_been_placed_successfully}`
            }
        }
    }
    topPart.push(
        <div key="bidTimer" className="bidTimeCntRt">
            <label> {phrase.time_left} </label>
            <div className="primeColor">
                <Timer
                    date_added={product.date_added}
                    date_closed={product.date_closed}
                    withText={0}
                ></Timer>
                <h6>{dateFormatFront(product.date_closed)}</h6>
            </div>
        </div>,
    )

    // Reserve Price Met? message
    if (product.userHasBid == 1) {
        if (parseFloat(product.wprice) >= parseFloat(product.rprice)) {
            reserveMetMessage = <span className="primeColor">{phrase.reserve_price_is_met}</span>
        } else {
            reserveMetMessage = <span className="red">{phrase.reserve_price_is_not_met}</span>
        }
    }

    return (
        <div className="biddingCard">
            <h3> {phrase.auction_details} </h3>
            {product.inventorytype == 1 ? (
                <div className="tpTag">
                    <div className="tpTagInner">
                        <span>Approved</span>
                        <h6>
                            <span>3rd</span> Party Seller
                        </h6>
                    </div>
                </div>
            ) : null}
            {/* bid status / award status message */}
            {product.market_status == 'open' ? (
                product.auctiontype == 'live' ? (
                    <div
                        className="bidMessage"
                        dangerouslySetInnerHTML={{ __html: product.bidMessage }}
                    ></div>
                ) : (
                    <div className="bidMessage primeColor"> {blindAuctionBidMessage} </div>
                )
            ) : (
                <div
                    className="bidMessage"
                    dangerouslySetInnerHTML={{ __html: product.bidMessage }}
                ></div>
            )}

            <div className="bidTimeCnt">
                <div className=" d-flex justify-content-center align-items-start">{topPart}</div>
                {viewHistory}
            </div>
            <div className="bidInputCnt">
                <Button
                    onClick={() => {
                        bidValue > 0 && setBidValue(parseInt(bidValue) - 1)
                    }}
                >
                    <span className="material-icons">remove</span>
                </Button>
                <div className="bcBidInput">
                    <label> {phrase.your_bid} </label>
                    {bidInput}
                </div>
                <Button onClick={() => setBidValue(parseInt(bidValue) + 1)}>
                    <span className="material-icons">add</span>
                </Button>
            </div>
            <h6 className="nextBidValue">
                {enterOrMoreMsg}
                <div>
                    {reserveMetMessage}
                    {errorMsg != '' ? (
                        <>
                            <br />
                            <span className="red">{errorMsg}</span>
                        </>
                    ) : null}
                </div>
            </h6>
            <div className="mt-3 mb-2">
                <div className="row bcActBtn flex-wrap">
                    <div className="col">
                        <Button
                            className="contactSeller"
                            onClick={contactPopupOpen}
                            disabled={isBuyer && !internalSelected ? false : true}
                        >
                            <span className="material-icons">chat</span>
                            <span> {phrase.contact_seller} </span>
                        </Button>
                    </div>
                    {submitBtn}
                </div>
            </div>
            <div>
                <Button
                    variant="outlined"
                    className="autoBid"
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    {phrase.autobidcaps} <span className="material-icons"> info </span>
                </Button>
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <h4> {phrase.autobid_msg_line1.replace(/<br>/g, '')} </h4>
                    <p> {phrase.autobid_msg_line2_outer} </p>
                </Popover>
            </div>
            {/* // Bid confirmation popup */}
            <Popup
                open={bidPopup}
                data={product}
                modaltitle={phrase.bid_confirmation}
                handleClose={popupClose}
                footer={
                    <BiddingItem
                        wsprice={bidValue}
                        product={product}
                        phrases={phrase}
                        handleClose={popupClose}
                    ></BiddingItem>
                }
            >
                <p>
                    {phrase.you_are_placing_a_bid_of} {product.currency_symbol} {bidValDisplay}.
                    {phrase.to_confirm_this_bid_click_below}
                </p>
                <div>
                    <h4>{phrase.autobid_msg_line1.replace(/<br>/g, '')}</h4>
                    <p>{phrase.autobid_msg_line2_outer}</p>
                </div>
            </Popup>
            {/* Contact Seller Popup */}
            <Popup
                open={contactPopup}
                handleClose={contactPopupClose}
                modaltitle={phrase.send_message}
                footer={
                    <>
                        <PrimaryButton label={phrase.send} onClick={contactSeller} />
                        <SecondaryButton label={phrase.cancel} onClick={contactPopupClose} />
                    </>
                }
            >
                <div className="row text-left">
                    <div className="col-2">
                        <label>{phrase.title}</label>
                    </div>
                    <div className="col-8">
                        <label>{product.title}</label>
                    </div>
                </div>
                <div className="row text-left">
                    <div className="col-2">
                        <label>{phrase.message}</label>
                    </div>
                    <div className="col-8">
                        <textarea
                            className="form-control"
                            id="contactSellerMsg"
                            rows="4"
                            placeholder={phrase.enter_your_message}
                        ></textarea>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default BiddingCard
