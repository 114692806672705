import { Divider, ListItem } from '@material-ui/core'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import './Dashboard.css'

// context
import CommonContext from '../../context/common/commonContext'

const SideNav = () => {
    const { phrase } = useContext(CommonContext)
    return (
        <div className="sideNav">
            <h6>Auction Status</h6>
            <ul>
                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/active">
                        {phrase.active}
                    </NavLink>
                </ListItem>

                <Divider />

                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/buy_now">
                        {phrase.buy_now}
                    </NavLink>
                </ListItem>

                <Divider />

                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/won">
                        {phrase.bids_won}
                    </NavLink>
                </ListItem>

                <Divider />

                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/lost">
                        {phrase.bids_lost}
                    </NavLink>
                </ListItem>

                <Divider />

                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard/watchlist">
                        {phrase.watch_list}
                    </NavLink>
                </ListItem>
            </ul>
        </div>
    )
}

export default SideNav
