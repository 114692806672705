import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { logo, siteName } from '../../../Utils'
import { Link } from 'react-router-dom'
import {
    Badge,
    Button,
    Divider,
    Drawer,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import AuthContext from '../../../context/auth/authContext'
import CommonContext from '../../../context/common/commonContext'
import AlertContext from '../../../context/alert/alertContext'
import './Header.css'
import AutopltContext from '../../../context/autopilot/autopltContext'
import UserContext from '../../../context/user/userContext'
import ProductContext from '../../../context/product/productContext'
import BidHistory from '../../organisms/BidHistory'
import Popup from '../../organisms/Popup'
import PrimaryButton from '../../atoms/PrimaryButton'

// Common Function
import { handleRedirectInternal, useCustomMediaQuery } from '../../../common/components'
import { apiCall } from '../../../common/api'
import SearchNav from '../SearchNav'
import CustomInput from '../../atoms/Inputs/CustomInput'

const Header = () => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const userContext = useContext(UserContext)

    const autopltContext = useContext(AutopltContext)
    const {
        searchValue,
        phrase,
        language,
        changeLanguage,
        msgUnreadCount,
        getMsgUnreadCount,
        clearNotification,
    } = commonContext

    const { setAlert } = alertContext
    const { user, isAuthenticated, isAdmin, logout } = authContext
    const [state, setState] = useState({
        top: false,
        right: false,
    })
    const history = useHistory()
    const { pathname } = useLocation()

    const [anchorLanguage, setAnchorLanguage] = useState(null)
    const [anchorNotif, setAnchorNotif] = useState(null)
    const [anchorMenu, setAnchorMenu] = useState(null)
    const [bellNotify, setBellNotify] = useState([])
    const [bellNotifyContent, setBellNotifyContent] = useState([])
    const [bellNotifyUnreadCount, setBellNotifyUnreadCount] = useState()
    const [sessionPopup, setSessionPopup] = useState(false)

    const handleMenuClose = () => {
        setAnchorLanguage(null)
        setAnchorNotif(null)
    }

    const switchLanguage = (e, lang) => {
        if (language != lang) {
            changeLanguage(lang)
            handleMenuClose()
        }
    }

    let languageKey = 'english'
    switch (language) {
        case 'English':
            languageKey = 'english'
            break
        case 'Mandarin':
            languageKey = 'mandarin'
            break
        case 'French':
            languageKey = 'french'
            break
        case 'Spanish':
            languageKey = 'spanish'
            break
        case 'Portugese':
            languageKey = 'portugese'
            break
        case 'BrazilPortugese':
            languageKey = 'brazil_portugese'
            break
        case 'English':
            languageKey = 'german'
            break
    }

    const {
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = productContext
    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext
    const {
        responseStatus: responseStatusAutoPlt,
        clearResponse: clearResponseAutoPlt,
    } = autopltContext

    // get notification asynchronously
    const getNotification = async () => {
        apiCall('get', '', '', '', 'notification')
            .then((response) => {
                setBellNotify(response.data.bellNotify)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const changeNotifyReadStatus = (id, read, lotId, masterLotId) => {
        let productId = 0
        if (masterLotId == 0) productId = lotId
        else productId = masterLotId

        // if already read, simply redirect
        if (read == 1) {
            handleRedirectInternal(history, `product/view/${productId}`)
        }
        // if not read, update the status of notification, and redirect
        else {
            apiCall('post', '', '', '', `updatebellsingle/${id}`)
            let bellNotifyCopy = [...bellNotify]
            const notifyIndex = bellNotifyCopy.findIndex((data) => {
                return data.id == id
            })
            bellNotifyCopy[notifyIndex].read = '1'
            setBellNotify(bellNotifyCopy)
            let unreadCount = bellNotifyUnreadCount
            setBellNotifyUnreadCount(unreadCount--)
            handleRedirectInternal(history, `product/view/${productId}`)
        }
    }

    const idleTime = useRef(0)
    const sessionInterval = useRef(null)
    const timerIncrement = () => {
        idleTime.current = idleTime.current + 1
        // After 8 hours of inactivity
        if (idleTime.current > 60 * 8) {
            setSessionPopup(true)
        }
    }

    const sessionPopupClose = () => {
        setSessionPopup(false)
        logout()
        clearInterval(sessionInterval.current)
        sessionInterval.current = null
    }

    useEffect(() => {
        if (isAuthenticated) {
            getNotification()
            getMsgUnreadCount()
            if (!sessionInterval.current) {
                //Increment the idle time counter every minute.
                sessionInterval.current = setInterval(timerIncrement, 60000) // 1 minute
                //Zero the idle timer on mouse movement.
                document.addEventListener('mousemove', (e) => {
                    idleTime.current = 0
                })
                document.addEventListener('keypress', (e) => {
                    idleTime.current = 0
                })
            }
        }
    }, [isAuthenticated])

    // Show More / Show Less link in notification bell
    const showMore = (index) => {
        let whiteSpaceCss = document.getElementById('notify_message_' + index).style.whiteSpace
        if (whiteSpaceCss == 'normal') {
            document.getElementById('notify_show_more_' + index).innerHTML = phrase.show_more + ' >'
            document.getElementById('notify_message_' + index).style.whiteSpace = 'nowrap'
        } else {
            document.getElementById('notify_show_more_' + index).innerHTML = phrase.show_less + ' >'
            document.getElementById('notify_message_' + index).style.whiteSpace = 'normal'
        }
    }

    const StyledMenuItem = withStyles({
        root: {
            width: 'auto',
            overflow: 'hidden',
            fontSize: '1rem',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
        },
    })(MenuItem)
    useEffect(() => {
        if (bellNotify.length > 0) {
            let unreadCount = 0
            let notifyContent = bellNotify.map((data, i) => {
                let content = ''
                switch (data.type) {
                    case 1:
                        content = phrase.congratulations_you_bought + ' ' + data.title
                        break
                    case 2:
                        content = phrase.bid_is_placed_on + ' ' + data.title
                        break
                    case 3:
                    case 4:
                        content = phrase.you_have_been_outbid_on + ' ' + data.title
                        break
                    case 5:
                        content =
                            data.title + ' ' + phrase.has_been_successfully_added_to_your_watchlist
                        break
                    case 6:
                        content = phrase.congratulations_you_won + ' ' + data.title
                        break
                    case 8:
                    case 9:
                        content = data.title + ' ' + phrase.is_ending_soon
                        break
                    case 10:
                        content =
                            phrase.preferred_product + ' ' + data.title + ' ' + phrase.is_posted
                        break
                    case 11:
                    case 12:
                        content = data.title + ' ' + phrase.is_price_dropped
                        break
                }
                let showMoreLink = true
                if (content.length < 26) showMoreLink = false
                if (data.read == '0') unreadCount++
                return (
                    <React.Fragment key={i}>
                        <StyledMenuItem onClick={handleMenuClose}>
                            <a
                                onClick={() =>
                                    changeNotifyReadStatus(
                                        data.id,
                                        data.read,
                                        data.project_id,
                                        data.linesublot,
                                    )
                                }
                            >
                                <h6
                                    id={'notify_message_' + i}
                                    style={
                                        data.read == '0'
                                            ? { fontWeight: 'bold', lineHeight: '1.3' }
                                            : { lineHeight: '1.3' }
                                    }
                                >
                                    {content}
                                </h6>
                            </a>
                        </StyledMenuItem>
                        {showMoreLink ? (
                            <div style={{ paddingLeft: '16px' }}>
                                <a onClick={() => showMore(i)} id={'notify_show_more_' + i}>
                                    {phrase.show_more} {'>'}
                                </a>
                            </div>
                        ) : null}

                        <Divider />
                    </React.Fragment>
                )
            })
            setBellNotifyContent(notifyContent)
            setBellNotifyUnreadCount(unreadCount)
        } else {
            setBellNotifyContent([])
            setBellNotifyUnreadCount(0)
        }
    }, [bellNotify])

    useEffect(() => {
        if (responseStatusUser) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusProduct) {
            setAlert(responseStatusProduct.message, responseStatusProduct.status)
            clearResponseProduct()
        }
        if (responseStatusAutoPlt) {
            setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            clearResponseAutoPlt()
        }
    }, [responseStatusUser, responseStatusProduct, responseStatusAutoPlt])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleClose = (history, path) => {
        setAnchorMenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const clearNotificationHandler = async () => {
        await clearNotification()
        getNotification()
    }

    const redirectDefaultSearch = () => {
        if (history.location.pathname == '/search') {
            window.location.reload()
        } else {
            handleRedirectInternal(history, 'search')
        }
    }

    return (
        <>
            {/* {console.log(localStorage, 'Window.localStorage')} */}
            {useCustomMediaQuery('(min-width: 992px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <header className="container-fluid">
                        <div className="customContainer d-flex justify-content-between align-items-center">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                {isAuthenticated ? (
                                    <a onClick={() => redirectDefaultSearch()}>
                                        <img src={logo} alt={`${siteName} logo`} />
                                    </a>
                                ) : (
                                    <Link to="/">
                                        <img src={logo} alt={`${siteName} logo`} />
                                    </Link>
                                )}

                                {isAuthenticated && (
                                    <ul className="d-flex justify-content-start align-items-center">
                                        {user.isBuyer || user.is_salesrep ? (
                                            <>
                                                <li>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/active"
                                                        isActive={() =>
                                                            [
                                                                '/dashboard/active',
                                                                '/dashboard/buy_now',
                                                                '/dashboard/won',
                                                                '/dashboard/lost',
                                                            ].includes(pathname)
                                                        }
                                                    >
                                                        {phrase.my_auctions}
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/watchlist"
                                                    >
                                                        <span className="material-icons">star</span>
                                                        {phrase.watch_list}
                                                    </NavLink>
                                                </li>
                                            </>
                                        ) : null}
                                    </ul>
                                )}
                            </div>
                            <div>
                                <div className="headRt deskNav d-flex justify-content-start align-items-center">
                                    <ul className="d-flex justify-content-start align-items-center">
                                        <li>
                                            <a
                                                activeClassName="active"
                                                href="https://www.procurri.com/about-procurri/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {phrase.about}
                                            </a>
                                        </li>
                                        <li>
                                            <Button
                                                aria-controls="languageMenu"
                                                aria-haspopup="true"
                                                onClick={(e) => setAnchorLanguage(e.currentTarget)}
                                            >
                                                {phrase[languageKey]}
                                                <span className="material-icons">
                                                    arrow_drop_down
                                                </span>
                                            </Button>
                                            <Menu
                                                id="languageMenu"
                                                anchorEl={anchorLanguage}
                                                keepMounted
                                                open={Boolean(anchorLanguage)}
                                                value={language}
                                                onClose={() => setAnchorLanguage(null)}
                                            >
                                                <MenuItem
                                                    value="English"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'English')
                                                    }
                                                >
                                                    {phrase.english}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Mandarin"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'Mandarin')
                                                    }
                                                >
                                                    {phrase.mandarin}
                                                </MenuItem>
                                                <MenuItem
                                                    value="French"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'French')
                                                    }
                                                >
                                                    {phrase.french}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Spanish"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'Spanish')
                                                    }
                                                >
                                                    {phrase.spanish}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Portugese"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'Portugese')
                                                    }
                                                >
                                                    {phrase.portugese}
                                                </MenuItem>
                                                <MenuItem
                                                    value="BrazilPortugese"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'BrazilPortugese')
                                                    }
                                                >
                                                    {phrase.brazil_portugese}
                                                </MenuItem>
                                                <MenuItem
                                                    value="German"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'German')
                                                    }
                                                >
                                                    {phrase.german}
                                                </MenuItem>
                                            </Menu>
                                        </li>
                                        {isAuthenticated ? (
                                            <>
                                                {user.isBuyer ? (
                                                    <>
                                                        {' '}
                                                        <li className="headCart">
                                                            <Button
                                                                aria-controls="notificationMenu"
                                                                aria-haspopup="true"
                                                                onClick={(e) =>
                                                                    setAnchorNotif(e.currentTarget)
                                                                }
                                                            >
                                                                <Badge
                                                                    color="primary"
                                                                    badgeContent={
                                                                        bellNotifyUnreadCount
                                                                    }
                                                                >
                                                                    <span className="material-icons">
                                                                        notifications
                                                                    </span>
                                                                </Badge>
                                                            </Button>
                                                            <Menu
                                                                id="notificationMenu"
                                                                anchorEl={anchorNotif}
                                                                keepMounted
                                                                open={Boolean(anchorNotif)}
                                                                onClose={() => setAnchorNotif(null)}
                                                                className="notificationMenu"
                                                            >
                                                                <div>
                                                                    <h4>{phrase.notifications}</h4>
                                                                    {bellNotifyContent.length >
                                                                    0 ? (
                                                                        bellNotifyContent
                                                                    ) : (
                                                                        <h5 className="noNotification">
                                                                            {
                                                                                phrase.no_new_notification
                                                                            }
                                                                        </h5>
                                                                    )}
                                                                    <Button
                                                                        onClick={
                                                                            clearNotificationHandler
                                                                        }
                                                                    >
                                                                        {phrase.clear}
                                                                    </Button>
                                                                </div>
                                                            </Menu>
                                                        </li>
                                                        <li className="headCart">
                                                            <Button
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        'message',
                                                                    )
                                                                }
                                                            >
                                                                <Badge
                                                                    color="primary"
                                                                    badgeContent={msgUnreadCount}
                                                                >
                                                                    <span className="material-icons">
                                                                        mail
                                                                    </span>
                                                                </Badge>
                                                            </Button>
                                                        </li>
                                                    </>
                                                ) : null}

                                                <li>
                                                    <Button
                                                        aria-controls="avatarMenu"
                                                        aria-haspopup="true"
                                                        onClick={(e) =>
                                                            setAnchorMenu(e.currentTarget)
                                                        }
                                                    >
                                                        {user.first_name}{' '}
                                                        <span className="material-icons">
                                                            arrow_drop_down
                                                        </span>
                                                    </Button>
                                                    <Menu
                                                        id="avatarMenu"
                                                        anchorEl={anchorMenu}
                                                        keepMounted
                                                        open={Boolean(anchorMenu)}
                                                        onClose={() => setAnchorMenu(null)}
                                                    >
                                                        {user.isBuyer ? (
                                                            <>
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'my_account',
                                                                        )
                                                                    }
                                                                >
                                                                    {phrase.my_account}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'my_preferences',
                                                                        )
                                                                    }
                                                                >
                                                                    {phrase.my_preferences}
                                                                </MenuItem>
                                                            </>
                                                        ) : (
                                                            <MenuItem
                                                                onClick={() =>
                                                                    window.open(
                                                                        global.site_url,
                                                                        '_blank', // <- This is what makes it open in a new window.
                                                                    )
                                                                }
                                                            >
                                                                Admin Module
                                                            </MenuItem>
                                                        )}

                                                        <MenuItem onClick={() => onLogOut()}>
                                                            Logout
                                                        </MenuItem>
                                                    </Menu>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/register"
                                                    >
                                                        Register
                                                    </NavLink>
                                                    <span className="divider">/</span>
                                                    <NavLink activeClassName="active" to="/login">
                                                        Login
                                                    </NavLink>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="customContainer mobHeader d-flex justify-content-between align-items-center">
                        {console.log(isAuthenticated, 'isAuthenticated')}
                        <div className="headLt d-flex justify-content-start">
                            {isAuthenticated ? (
                                <a onClick={() => redirectDefaultSearch()}>
                                    <img src={logo} alt={`${siteName} logo`} />
                                </a>
                            ) : (
                                <Link to="/">
                                    <img src={logo} alt={`${siteName} logo`} />
                                </Link>
                            )}
                        </div>
                        <ul className="d-flex justify-content-start ml-auto align-items-center">
                            {isAuthenticated && (
                                <>
                                    <li className="headCart">
                                        <Button onClick={toggleDrawer('top', true)}>
                                            <span className="material-icons">search</span>
                                        </Button>
                                    </li>
                                    <li className="headCart">
                                        <Button
                                            aria-controls="notificationMenu"
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorNotif(e.currentTarget)}
                                        >
                                            <Badge
                                                color="primary"
                                                badgeContent={bellNotifyUnreadCount}
                                            >
                                                <span className="material-icons">
                                                    notifications
                                                </span>
                                            </Badge>
                                        </Button>
                                        <Menu
                                            id="notificationMenu"
                                            anchorEl={anchorNotif}
                                            keepMounted
                                            open={Boolean(anchorNotif)}
                                            onClose={() => setAnchorNotif(null)}
                                            className="notificationMenu"
                                        >
                                            <div>
                                                <h4>Notifications</h4>
                                                <MenuItem onClick={handleMenuClose}>
                                                    <h6>
                                                        Bid is placed on lorem ipsum dolor sit amet,
                                                        consectetur adipiscing elit
                                                    </h6>
                                                    <a
                                                        href="#"
                                                        className="d-flex align-items-center"
                                                    >
                                                        Show more
                                                        <span className="material-icons">
                                                            chevron_right
                                                        </span>
                                                    </a>
                                                </MenuItem>
                                                <Divider />
                                                <Button>Clear</Button>
                                            </div>
                                        </Menu>
                                    </li>
                                    <li className="headCart">
                                        <Button
                                            onClick={() =>
                                                handleRedirectInternal(history, 'message')
                                            }
                                        >
                                            <Badge color="primary" badgeContent={msgUnreadCount}>
                                                <span className="material-icons">mail</span>
                                            </Badge>
                                        </Button>
                                    </li>
                                </>
                            )}
                            <li>
                                <Button
                                    className="respNavBtn"
                                    onClick={toggleDrawer('right', true)}
                                >
                                    <span className="material-icons">menu</span>
                                </Button>
                            </li>
                        </ul>
                    </header>
                    <Drawer
                        className="headerSearch"
                        anchor={'top'}
                        open={state['top']}
                        onClose={toggleDrawer('top', false)}
                        onOpen={toggleDrawer('top', true)}
                    >
                        <CustomInput
                            label="Search"
                            size="regular"
                            placeholder="Search for products"
                            endadornment={
                                <Button>
                                    <span className="material-icons">search</span>
                                </Button>
                            }
                        />
                    </Drawer>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    {isAuthenticated ? (
                                        <a onClick={() => redirectDefaultSearch()}>
                                            <img src={logo} alt={`${siteName} logo`} />
                                        </a>
                                    ) : (
                                        <Link to="/">
                                            <img src={logo} alt={`${siteName} logo`} />
                                        </Link>
                                    )}
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="headRtNav">
                                    <span>
                                        {isAuthenticated && (
                                            <>
                                                {/* <ListItem button> */}
                                                <SearchNav />
                                                {/* </ListItem> */}
                                                <Divider className="my-2" />
                                            </>
                                        )}
                                        <ListItem button>
                                            <NavLink activeClassName="active" to="/about">
                                                {phrase.about}
                                            </NavLink>
                                        </ListItem>
                                        {isAuthenticated ? (
                                            <>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/my_account"
                                                    >
                                                        My Account
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/my_preferences"
                                                    >
                                                        My Preferences
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button onClick={() => onLogOut()}>
                                                    <Link to="/dashboard/myPreferences">
                                                        Logout
                                                    </Link>
                                                </ListItem>
                                            </>
                                        ) : (
                                            <>
                                                <ListItem button>
                                                    <NavLink activeClassName="active" to="/login">
                                                        Login
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/register"
                                                    >
                                                        Register
                                                    </NavLink>
                                                </ListItem>
                                            </>
                                        )}
                                    </span>
                                    <li className="headRtLang">
                                        <Divider />
                                        <Button
                                            aria-controls="languageMenu"
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorLanguage(e.currentTarget)}
                                        >
                                            {phrase[languageKey]}
                                            <span className="material-icons">arrow_drop_down</span>
                                        </Button>
                                        <Menu
                                            id="languageMenu"
                                            anchorEl={anchorLanguage}
                                            keepMounted
                                            open={Boolean(anchorLanguage)}
                                            value={language}
                                            onClose={() => setAnchorLanguage(null)}
                                        >
                                            <MenuItem
                                                value="English"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'English')
                                                }
                                            >
                                                {phrase.english}
                                            </MenuItem>
                                            <MenuItem
                                                value="Mandarin"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Mandarin')
                                                }
                                            >
                                                {phrase.mandarin}
                                            </MenuItem>
                                            <MenuItem
                                                value="French"
                                                onClick={(event) => switchLanguage(event, 'French')}
                                            >
                                                {phrase.french}
                                            </MenuItem>
                                            <MenuItem
                                                value="Spanish"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Spanish')
                                                }
                                            >
                                                {phrase.spanish}
                                            </MenuItem>
                                            <MenuItem
                                                value="Portugese"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Portugese')
                                                }
                                            >
                                                {phrase.portugese}
                                            </MenuItem>
                                            <MenuItem
                                                value="BrazilPortugese"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'BrazilPortugese')
                                                }
                                            >
                                                {phrase.brazil_portugese}
                                            </MenuItem>
                                            <MenuItem
                                                value="German"
                                                onClick={(event) => switchLanguage(event, 'German')}
                                            >
                                                {phrase.german}
                                            </MenuItem>
                                        </Menu>
                                    </li>
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <BidHistory />

            {/* Session Inactividy Popup Message */}
            <Popup
                open={sessionPopup}
                modaltitle={phrase.message}
                handleClose={sessionPopupClose}
                footer={<PrimaryButton label={phrase.close} onClick={sessionPopupClose} />}
            >
                <p>Your session has expired due to inactivity!</p>
            </Popup>
        </>
    )
}
export default Header
