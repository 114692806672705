import React, { useState, useContext, useEffect, useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import PropTypes from 'prop-types'
import StepLabel from '@material-ui/core/StepLabel'
import clsx from 'clsx'
import { mapData, getLanguageArr } from '../../common/components'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import CommonContext from '../../context/common/commonContext'
import AuthContext from '../../context/auth/authContext'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { Link } from 'react-router-dom'
import CheckBox from '../../components/atoms/CheckBox'
import './Registration.css'
import { logo, siteName } from '../../Utils'
import StepConnector from '@material-ui/core/StepConnector'
import Loader from '../../components/molecules/Loader'
import csc from 'country-state-city'
import { terms_of_service } from './Terms'
import {
    step1InitialValues,
    step1ValidationSchema,
    step1Data,
    step2InitialValues,
    step2ValidationSchema,
    step2Data,
    step3InitialValues,
    step3ValidationSchema,
    step4InitialValues,
    step4ValidationSchema,
} from './steps'
import { apiCall } from '../../common/api'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor: 'var(--primColor)',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: 'var(--primColor)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
    root: {
        width: '45px',
        height: '45px',
        color: '#c9c9c9',
        border: '2px solid #c9c9c9',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        color: 'var(--primColor)',
        borderColor: 'var(--primColor)',
    },
    completed: {
        filter: 'var(--primColor)',
        color: 'var(--primColor)',
        borderColor: 'var(--primColor)',
    },
})

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    const icons = {
        1: <span className="material-icons">perm_contact_calendar</span>,
        2: <span className="material-icons">assignment</span>,
        3: <span className="material-icons">assignment_turned_in</span>,
        4: <span className="material-icons">lock</span>,
        5: <span className="material-icons">done</span>,
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[props.icon]}
        </div>
    )
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
}

const Registration = (props) => {
    const classes = useStyles()
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const history = useHistory()
    const { nonloginToken, allCountries, phrase, language } = commonContext
    const { register } = authContext
    const [genericData, setGenericData] = useState({})
    const [activeStep, setActiveStep] = useState(0)
    const [statesinLocal, setStatesinLocal] = useState([])
    const categoryList = useRef([])
    const [loading, setLoading] = useState(false)
    const formikValues = useRef({})
    const formRef = useRef()
    const steps = [
        phrase.enter_in_your_credentials,
        `${phrase.enter} ${phrase.location}`,
        phrase.registerpagestatic_11,
        phrase.terms_conditions,
        phrase.finish,
    ]
    const languageArr = getLanguageArr(phrase)
    const [afterRegister, setAfterRegister] = useState(null)

    // Initial Values for each step
    const initialvalues0 = step1InitialValues()
    const initialvalues1 = step2InitialValues()
    const initialvalues2 = step3InitialValues()
    const initialvalues3 = step4InitialValues()

    // Validation Schema for each step
    const validateStep1 = step1ValidationSchema(phrase)
    const validateStep2 = step2ValidationSchema(phrase)
    const validateStep3 = step3ValidationSchema(phrase)
    const validateStep4 = step4ValidationSchema(phrase)

    // formik validate each step, and handle submission
    const formik = useFormik({
        initialValues: (() => {
            switch (Number(activeStep)) {
                case 0:
                    return initialvalues0
                    break
                case 1:
                    return initialvalues1
                    break
                case 2:
                    return initialvalues2
                    break
                case 3:
                    return {}
                    break
                case 4:
                    return initialvalues3
                    break
            }
        })(),
        validationSchema: (() => {
            switch (Number(activeStep)) {
                case 0:
                    return validateStep1
                case 1:
                    return validateStep2
                case 2:
                    return validateStep3
                case 3:
                    break
                case 4:
                    return validateStep4
            }
        })(),
        onSubmit: async (values) => {
            if (activeStep == 0) {
                try {
                    // Check Unique Email
                    let queryUrl = `check_unique?email=${values.email}&site_id=${global.site_id}`
                    apiCall('get', '', '', '', queryUrl).then((results) => {
                        console.log('RESULTS: ', results)
                        // this email is not unique
                        if (!results.data.status) {
                            formik.setFieldError(
                                'email',
                                phrase.email_address_has_already_been_used,
                            )
                        }
                        setActiveStep((prevActiveStep) => prevActiveStep + 1)
                    })
                } catch (e) {
                    console.log('step 1 err: ', e)
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                }
            }
            // STEP 2
            else if (activeStep == 1) {
                if (typeof values.state === 'undefined') {
                    values.state = ''
                    formik.setFieldError('state', `${phrase.enter} ${phrase.state}`)
                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                }
            }
            // STEP 3 - choose preferred categories
            else if (activeStep == 2) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
            // STEP 4
            else if (activeStep == 3) {
                if (values.agree.length == 0) {
                    formik.setFieldError('agree', phrase.registerpagestatic_32)
                } else {
                    let payload = {}
                    if (Array.isArray(values['agree'])) {
                        payload['termsagree'] = values['agree'][0]
                    }
                    payload['business_type'] = values['business_type']
                    if (Array.isArray(values['categories'])) {
                        payload['interestin'] = values['categories'].join()
                    }
                    let { name: countryName } = csc.getCountryById(values['counntry'])
                    let { name: stateName } = csc.getStateById(values['state'])
                    payload['city'] = values['city']
                    payload['companyname'] = values['company_name']
                    payload['confirm_email'] = values['confirm_email']
                    payload['password'] = values['password']
                    payload['password1'] = values['password1']
                    payload['country'] = countryName
                    payload['state'] = stateName
                    payload['zipcode'] = values['zip_code']
                    payload['email'] = values['email']
                    payload['address'] = values['street_address']
                    payload['username'] = values['username']
                    payload['landline'] = values['landline']
                    payload['first_name'] = values['first_name']
                    payload['last_name'] = values['last_name']
                    payload['phone'] = values['phone']
                    payload['language'] = values['language']
                    payload['nonloginToken'] = nonloginToken
                    payload['checkDuplicateUsername'] = 'true'
                    if (afterRegister) {
                        payload['id'] = afterRegister.userId
                        const {
                            data: {
                                status,
                                code,
                                msg: { phoneError, landlineErr, phoneErrMsg },
                            },
                        } = await register(payload, 'after_register')
                        if (!status && code === 400) {
                            setActiveStep(0)
                            if (phoneError > 0) {
                                console.log('111111111111')
                                formik.setFieldError('phone', phoneErrMsg)
                            }
                            if (landlineErr > 0) {
                                console.log('222222222222')
                                formik.setFieldError('landline', phoneErrMsg)
                            }
                            return
                        }
                        history.push('/search')
                    } else {
                        const {
                            data: {
                                status,
                                code,
                                msg: {
                                    usernameLen,
                                    emailslen,
                                    usernameErrMsg,
                                    emailErrMsg,
                                    phoneError,
                                    landlineErr,
                                    phoneErrMsg,
                                },
                            },
                        } = await register(payload)
                        if (!status && code === 400) {
                            setActiveStep(0)
                            if (usernameLen > 0) {
                                formik.setFieldError('username', usernameErrMsg)
                            }
                            if (emailslen > 0) {
                                formik.setFieldError('email', emailErrMsg)
                            }
                            if (phoneError > 0) {
                                console.log('111111111111')
                                formik.setFieldError('phone', phoneErrMsg)
                            }
                            if (landlineErr > 0) {
                                console.log('222222222222')
                                formik.setFieldError('landline', phoneErrMsg)
                            }
                            return
                        }
                        setActiveStep((prevActiveStep) => prevActiveStep + 1)
                    }
                }
            } else if (activeStep === 4) {
                history.push('/login')
            }
        },
    })

    // Input Fields for each step
    const step1 = step1Data(formik, phrase, languageArr)
    let step2 = step2Data(formik, phrase, allCountries, statesinLocal)

    useEffect(() => {
        document.title = global.site_title + ' | Registration'
        setLoading(true)
        if (props.match.params.token) {
            // get user details
            apiCall(
                'get',
                '',
                '',
                '',
                `getUserDetailsFromToken/${props.match.params.token}/${global.site_id}`,
            ).then((userDetails) => {
                formik.values.first_name = userDetails.data.data.first_name
                formik.values.last_name = userDetails.data.data.last_name
                formik.values.company_name = userDetails.data.data.companyname
                formik.values.email = userDetails.data.data.email
                formik.values.confirm_email = userDetails.data.data.email
                setAfterRegister({ userId: userDetails.data.data.id })
            })
        }
        apiCall('get', '', '', '', `register?site_id=${global.site_id}`)
            .then((results) => {
                if (results.data.success) {
                    setGenericData(results.data.data)
                    categoryList.current = results.data.data.categories
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        const { id, name } = csc.getCountryById(formik.values.counntry)
        let states = csc.getStatesOfCountry(id)
        states = states.map((ele) => {
            let o = {
                show: ele.name,
                value: ele.id,
            }
            return o
        })
        setStatesinLocal(states)
    }, [formik.values.counntry])

    // Category Options
    const RadioOptions = categoryList.current.map((cat, i) => {
        return {
            id: i,
            description: (
                <>
                    <p>{cat.name}</p>
                </>
            ),
            formik: formik,
            name: 'categories',
            value: cat.id,
            checked() {
                if (typeof formik.values !== 'undefined') {
                    if (typeof formik.values.categories !== 'undefined') {
                        if (Array.isArray(formik.values.categories)) {
                            if (formik.values.categories.length) {
                                let index = formik.values.categories
                                    .map((ele) => Number(ele))
                                    .indexOf(this.value)
                                return index >= 0 ? true : false
                            }
                        }
                    }
                }
                return false
            },
        }
    })

    useEffect(() => {
        step2 = step2.map((ele) => {
            if (ele.label === 'Country') {
                ele.options = allCountries
            }
            return ele
        })
        setStep2_state(step2)
    }, [allCountries])

    useEffect(() => {
        let ster2arr = [...step2state]
        ster2arr = ster2arr.map((ele) => {
            if (ele.label === 'State') {
                ele.options = statesinLocal
            }
            return ele
        })
        setStep2_state(ster2arr)
    }, [statesinLocal])

    const [step1s, setStep1s] = useState(step1)
    const [step2state, setStep2_state] = useState(step2)
    const [m, setM] = useState({})

    useEffect(() => {
        setM(mapData(step1s))
    }, [step1s])

    useEffect(() => {
        if (activeStep == 0 || activeStep == 1) {
            let firstErrorFieldName = Object.keys(formik.errors)[0]
            if (firstErrorFieldName) {
                formRef.current[firstErrorFieldName].focus()
            }
        }
    }, [formik.submitCount])

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <div className="row">{Object.values(mapData(step1))}</div>
            case 1:
                return (
                    <div className="row">
                        <div className="d-flex flex-wrap">
                            {step2state.length ? Object.values(mapData(step2)) : null}
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div className="row">
                        <div className="regCategories">
                            {RadioOptions.map((data, index) => (
                                <div className="categorySelect">
                                    <CheckBox
                                        name={data.name}
                                        label={data.description}
                                        value={data.value}
                                        onchange={formik.handleChange}
                                        checked={data.checked()}
                                    />
                                </div>
                            ))}
                            <p style={{ color: 'red' }}>
                                {formik.errors['categories']
                                    ? formik.errors['categories']
                                    : delete formik.errors['categories']}
                            </p>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="regTerms"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        language == 'English'
                                            ? genericData.contentterms.for_english
                                            : language == 'Mandarin'
                                            ? genericData.contentterms.for_mandarin
                                            : language == 'French'
                                            ? genericData.contentterms.for_french
                                            : language == 'Spanish'
                                            ? genericData.contentterms.for_spanish
                                            : language == 'Portugese'
                                            ? genericData.contentterms.for_portugese
                                            : language == 'BrazilPortugese'
                                            ? genericData.contentterms.for_brazilportugese
                                            : genericData.contentterms.for_german,
                                }}
                            ></div>
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className="row">
                        <div className="regSuccess">
                            <h3>{phrase.youre_done}!</h3>
                            <h5>{phrase.registerpagestatic_34}</h5>
                        </div>
                    </div>
                )
            default:
                return 'Unknown stepIndex'
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    return (
        <section className="container">
            {loading ? (
                <Loader />
            ) : Object.keys(genericData).length > 0 ? (
                <div className="registration d-flex justify-content-between">
                    <div className="regBox">
                        <Link to="/">
                            <img className="brandLogo" src={logo} alt={`${siteName} logo`} />
                        </Link>
                        <h2 className="regTitle">
                            <span>{phrase.registration}</span>
                        </h2>
                        <h4 className="stepLabel">
                            Step {activeStep + 1}:{' '}
                            {steps.filter((steps, index) => index === activeStep)}
                        </h4>
                        <form action="" onSubmit={formik.handleSubmit} ref={formRef}>
                            <div className={classes.root}>
                                <Stepper
                                    activeStep={activeStep}
                                    alternativeLabel
                                    connector={<ColorlibConnector />}
                                >
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel
                                                StepIconComponent={ColorlibStepIcon}
                                            ></StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <div>
                                    {activeStep !== steps.length && (
                                        <div>
                                            {getStepContent(activeStep)}
                                            {activeStep === 3 ? (
                                                <div className="col-12 regTnc">
                                                    <CheckBox
                                                        key={activeStep}
                                                        name={'agree'}
                                                        value={1}
                                                        onchange={formik.handleChange}
                                                        checked={
                                                            formik.values.agree
                                                                ? formik.values.agree[0] == '1'
                                                                    ? true
                                                                    : false
                                                                : false
                                                        }
                                                        label={[phrase.registerpagestatic_33]}
                                                    />
                                                    <p style={{ color: 'red' }}>
                                                        {formik.errors['agree']
                                                            ? formik.errors['agree']
                                                            : delete formik.errors['agree']}
                                                    </p>
                                                </div>
                                            ) : null}
                                            <div className="crActBtn">
                                                {activeStep > 0 && (
                                                    <SecondaryButton
                                                        disabled={activeStep === 0}
                                                        label={phrase.back}
                                                        onClick={handleBack}
                                                        className={classes.backButton}
                                                    />
                                                )}
                                                <PrimaryButton
                                                    label={
                                                        activeStep === steps.length - 1
                                                            ? phrase.finish
                                                            : phrase.next
                                                    }
                                                    type="submit"
                                                />
                                            </div>
                                            {activeStep === 0 && (
                                                <div className="regMiscAction">
                                                    <p>
                                                        <span className="material-icons">
                                                            arrow_back
                                                        </span>
                                                        <Link
                                                            to="/login"
                                                            onMouseDown={(e) => {
                                                                e.preventDefault()
                                                            }}
                                                        >
                                                            Back to Login
                                                        </Link>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            ) : null}
        </section>
    )
}

export default Registration
