import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

// Style / css
import './ProductCard.css'

// Components
import FavouriteCheckbox from '../../atoms/FavoriteCheckbox'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import Timer from '../../../common/timer'
import AddDispute from './AddDispute'

// Context
import AuthContext from '../../../context/auth/authContext'
import CommonContext from '../../../context/common/commonContext'

// Common Function
import { dateFormatFront } from '../../../common/components'
import { handleRedirectInternal } from '../../../common/components'

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)

    const { phrase } = commonContext
    const { isAuthenticated, user } = authContext

    const [product, setProduct] = useState()
    const [disputePopup, setDisputePopup] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const openDisputePopup = () => {
        setDisputePopup(true)
    }
    const closeDisputePopup = () => {
        setDisputePopup(false)
    }

    let categories = ''
    if (product) {
        for (let i in product.multiplecategoryshow) {
            if (i == product.multiplecategoryshow.length - 1) {
                // last index
                //categories += product.multiplecategoryshow[i]
                categories += phrase[product.multiplecategoryshow[i]]
            } else {
                //categories += product.multiplecategoryshow[i] + ', '
                categories += phrase[product.multiplecategoryshow[i]] + ','
            }
        }
    }

    let dashboardLink = null
    if (props.dashboard && product) {
        if (props.dashboardType == 'won' || props.dashboardType == 'buy_now') {
            dashboardLink = (
                <div>
                    <div className="mb-1">
                        <Link to={`/invoice/${product.invoice_id}`}>
                            <PrimaryButton label={phrase.view + ' ' + phrase.invoice} />
                        </Link>
                    </div>
                    <div>
                        <PrimaryButton
                            onClick={() => openDisputePopup()}
                            label={phrase.add + ' ' + phrase.dispute}
                        />
                        <AddDispute
                            open={disputePopup}
                            product={product}
                            openDisputePopup={() => openDisputePopup()}
                            closeDisputePopup={() => closeDisputePopup()}
                        />
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="productCardGrid">
            {product ? (
                <>
                    <div className="pcgImg">
                        {(props.dashboard &&
                            props.dashboardType != 'watchlist' &&
                            props.dashboardType != 'active') ||
                        props.completed ? null : user.isBuyer || user.is_salesrep ? (
                            <FavouriteCheckbox
                                watchlisted={product.watchlistid}
                                project_id={product.id}
                                isDashboard={props.dashboard}
                            />
                        ) : null}
                        <Link to={`/product/view/${product.id}`}>
                            <img
                                src={global.site_url + '/uploads/product/' + product.avatar}
                                alt={product.title}
                            />
                        </Link>

                        {props.completed && <h4 className="aucEnded">Auction Ended</h4>}
                        {isAuthenticated && (
                            <>
                                {product.bidtopstatus === 'outbid' && (
                                    <h4 className="productWinningStatus outbid">OUTBID</h4>
                                )}
                                {product.bidtopstatus === 'winner' && (
                                    <h4 className="productWinningStatus winning">WINNING</h4>
                                )}
                                {product.bidtopstatus === 'won' && (
                                    <h4 className="productWinningStatus won">WON</h4>
                                )}
                                {product.bidtopstatus === 'lost' && (
                                    <h4 className="productWinningStatus lost">LOST</h4>
                                )}
                            </>
                        )}
                    </div>
                    <h2 className="gridProdTitle">
                        <Link to={`/product/view/${product.id}`}>{product.title}</Link>
                    </h2>
                    <div className="gridLotDetails">
                        <p>
                            <label>{phrase.condition}</label> {phrase[product.condition_language]}
                        </p>
                        <p>
                            <label> {phrase.no_of_items} </label> {product.qty} {phrase.items}
                        </p>
                    </div>
                    <div className="gridLotDetails">
                        <p>
                            <label> UPC/SKU </label>
                            {product.upc}
                        </p>
                        <p>
                            <label> {phrase.category} </label>
                            {categories}
                        </p>
                    </div>
                    <div className="gridLotDetails">
                        <p>
                            <label> {phrase.lot_size} </label>
                            {phrase[product.lottype]} {phrase.lot}
                        </p>
                        <p>
                            <label> {phrase.auction_type} </label>
                            {product.auctiontype == 'live'
                                ? phrase.live_auction
                                : product.auctiontype == 'lineitem'
                                ? phrase.line_item_auction
                                : phrase.blind_auction}
                        </p>
                    </div>
                    <div className="gridLotDetails">
                        <p>
                            <label>{phrase.manufacturer}</label>
                            {product.manufacturer}
                        </p>
                        <p>
                            <label>Model</label>
                            {product.modelnumber}
                        </p>
                    </div>
                    <div className="gridLotDetails">
                        <p>
                            <label>Size</label>
                            {product.size}
                        </p>
                        <p>
                            <label>{phrase.finish}</label>
                            {product.finish}
                        </p>
                    </div>
                    <div className="gridLotDetails fr1">
                        <p>
                            <label> {phrase.location} </label>
                            {product.city}, {product.state}, {product.country}
                        </p>
                    </div>
                    {product.market_status === 'open' || props.completed ? (
                        <>
                            <div className="priceTimeCnt">
                                {product.market_status === 'open' && !props.completed ? (
                                    product.auctiontype !== 'lineitem' ? (
                                        <>
                                            {product.auctiontype == 'live' ? (
                                                <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                                    <h6>{phrase.current_bid}: </h6>
                                                    <p className="d-flex justify-content-between align-items-center">
                                                        <span>
                                                            {product.currency_symbol}{' '}
                                                            {product.wprice}
                                                        </span>
                                                    </p>
                                                </div>
                                            ) : null}

                                            <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                                <h6>{phrase.time_left}:</h6>
                                                <p className="d-flex justify-content-between align-items-center">
                                                    <span></span>
                                                    {product.date_closed ? (
                                                        <Timer
                                                            date_added={product.date_added}
                                                            date_closed={product.date_closed}
                                                            withText={0}
                                                        ></Timer>
                                                    ) : null}
                                                </p>
                                            </div>

                                            {/* To resolve spacgin issue */}
                                            {product.auctiontype != 'live' ? (
                                                <div className="gridBidInfo d-flex justify-content-between align-items-center"></div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                                {product.market_status === 'open' &&
                                                !props.completed ? (
                                                    <>
                                                        <h6>{phrase.time_left}:</h6>
                                                        <p className="d-flex justify-content-between align-items-center">
                                                            <span></span>
                                                            {product.date_closed ? (
                                                                <Timer
                                                                    date_added={product.date_added}
                                                                    date_closed={
                                                                        product.date_closed
                                                                    }
                                                                    withText={0}
                                                                ></Timer>
                                                            ) : null}
                                                        </p>
                                                    </>
                                                ) : null}
                                            </div>
                                            <div className="gridBidInfo d-flex justify-content-between align-items-center"></div>
                                        </>
                                    )
                                ) : null}

                                {props.completed ? null : (
                                    <h5 className="prodEndDate">
                                        {dateFormatFront(product.date_closed)}
                                    </h5>
                                )}
                            </div>

                            <div className={`gridBidBox ${props.completed && 'ended'}`}>
                                <Link to={`/product/view/${product.id}`}>
                                    <PrimaryButton
                                        label={
                                            props.completed
                                                ? phrase.view_ended_auction
                                                : phrase.view
                                        }
                                    />
                                </Link>
                                {/* <Bidding data={product} /> */}
                            </div>
                        </>
                    ) : props.dashboard ? (
                        dashboardLink
                    ) : (
                        <>
                            {product.bidtopstatus === 'won' && (
                                <div className="actionAfterWin">
                                    {parseInt(product.buynowpaid, 10) === 1 ? (
                                        <>
                                            <SecondaryButton
                                                label={phrase.view + ' ' + phrase.invoice}
                                                btnSize="small"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `invoice/${product.invoice_id}`,
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <PrimaryButton
                                                label="Make payment"
                                                btnSize="small"
                                                onClick={() =>
                                                    handleRedirectInternal(history, 'cart')
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="gridBidBox">
                                <Link to={`/product/view/${product.id}`}>
                                    <PrimaryButton label={phrase.view_ended_auction} />
                                </Link>
                                {/* <Bidding data={product} /> */}
                            </div>
                        </>
                    )}
                </>
            ) : null}
        </div>
    )
}

export default Grid
