import React from 'react'
import TextField from '@material-ui/core/TextField'
import { FormControl, InputLabel, Select } from '@material-ui/core'

const CustomSelect = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    return (
        <div className="customSelect">
            {props.selectType === 'noBorder' ? (
                <FormControl>
                    <InputLabel id={props.id}>{props.label}</InputLabel>
                    <Select
                        id={props.id}
                        size={props.size}
                        label={props.label}
                        selectprops={{
                            native: true,
                        }}
                        fullWidth
                        margin="none"
                        variant="outlined"
                        inputlableprops={{
                            shrink: shrink,
                        }}
                        disabled={props.disabled}
                        name={props.name}
                        value={props.value}
                        autoFocus={props.autoFocus}
                        onChange={props.onChange || props.onChange}
                        error={props.error}
                        helperText={props.helperText}
                    >
                        {props.children}
                    </Select>
                </FormControl>
            ) : (
                <FormControl>
                    <TextField
                        id={props.id}
                        select
                        size={props.size}
                        label={props.label}
                        selectprops={{
                            native: true,
                        }}
                        fullWidth
                        margin="normal"
                        inputlableprops={{
                            shrink: shrink,
                        }}
                        variant="outlined"
                        disabled={props.disabled}
                        name={props.name}
                        value={props.value}
                        autoFocus={props.autoFocus}
                        onChange={props.onChange || props.onChange}
                        error={props.error}
                        helperText={props.helperText}
                    >
                        {props.children}
                    </TextField>
                </FormControl>
            )}
        </div>
    )
}

export default CustomSelect
