import React, { useState, useContext, useEffect, useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import PropTypes from 'prop-types'
import StepLabel from '@material-ui/core/StepLabel'
import clsx from 'clsx'
import { mapData, getLanguageArr } from '../../common/components'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import CommonContext from '../../context/common/commonContext'
import AuthContext from '../../context/auth/authContext'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { Link } from 'react-router-dom'
import CheckBox from '../../components/atoms/CheckBox'
import '../Registration/Registration.css'
import { logo, siteName } from '../../Utils'
import StepConnector from '@material-ui/core/StepConnector'
import csc from 'country-state-city'
import { apiCall } from '../../common/api'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor: 'var(--primColor)',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: 'var(--primColor)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
    root: {
        width: '45px',
        height: '45px',
        color: '#c9c9c9',
        border: '2px solid #c9c9c9',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        color: 'var(--primColor)',
        borderColor: 'var(--primColor)',
    },
    completed: {
        filter: 'var(--primColor)',
        color: 'var(--primColor)',
        borderColor: 'var(--primColor)',
    },
})

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    const icons = {
        1: <span className="material-icons">perm_contact_calendar</span>,
        2: <span className="material-icons">assignment</span>,
        3: <span className="material-icons">assignment_turned_in</span>,
        4: <span className="material-icons">lock</span>,
        5: <span className="material-icons">done</span>,
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[props.icon]}
        </div>
    )
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
}

const SellerRegistration = () => {
    const classes = useStyles()
    const { phrase } = useContext(CommonContext)
    const [completed, setCompleted] = useState(false)
    const history = useHistory()
    const formRef = useRef()

    useEffect(() => {
        document.title = 'Procurri | Seller Registration'
    }, [])

    const initialValues = {
        first_name: '',
        last_name: '',
        company_name: '',
        phone: '',
        email: '',
        seller_type: '',
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object({
        first_name: Yup.string().required(`${phrase.enter} ${phrase.first_name}`),
        last_name: Yup.string().required(`${phrase.enter} ${phrase.last_name}`),
        company_name: Yup.string().required(`${phrase.enter} ${phrase.company_name}`),
        phone: Yup.string().required(phrase.invalid_number),
        //.matches(phoneRegExp, phrase.invalid_number),
        email: Yup.string().required(`${phrase.enter} ${phrase.email}`),
        seller_type: Yup.string().required(),
    })

    const formik = useFormik({
        initialValues: (() => {
            return initialValues
        })(),
        validationSchema: (() => {
            return validationSchema
        })(),
        onSubmit: async (values) => {
            let payload = {}
            payload['companyname'] = values['company_name']
            payload['email'] = values['email']
            payload['first_name'] = values['first_name']
            payload['last_name'] = values['last_name']
            payload['phone'] = values['phone']
            payload['seller_type'] = values['seller_type']
            payload['site_id'] = global.site_id

            try {
                // Check Unique Email
                let queryUrl = `check_unique?email=${values.email}&site_id=${global.site_id}`
                let results = await apiCall('get', '', '', '', queryUrl)
                // email not unique
                if (!results.data.status) {
                    formik.setFieldError('email', phrase.email_address_has_already_been_used)
                    return false
                }

                // submit form
                let submitSeller = await apiCall('post', '', payload, '', 'register-save-seller')
                if (submitSeller.data.msg == 'success') {
                    setCompleted(true)
                } else {
                    formik.setFieldError('email', phrase.email_address_has_already_been_used)
                    return false
                }
            } catch (e) {
                console.log('err: ', e)
            }
        },
    })

    useEffect(() => {
        const firstErrorFieldName = Object.keys(formik.errors)[0]

        if (firstErrorFieldName) {
            formRef.current[firstErrorFieldName].focus()
        }
    }, [formik.submitCount])

    const inputData = [
        {
            label: phrase.first_name,
            name: 'first_name',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.first_name}`,
            class: 'col-md-6 col-12',
            variant: 'outlined',
            autoFocus: true,
            formik: formik,
        },
        {
            label: phrase.last_name,
            name: 'last_name',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.last_name}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },

        {
            label: phrase.phone_number,
            name: 'phone',
            type: 'countrySelect',
            defaultCountry: 'us',
            regions: 'america',
            placeholder: `${phrase.enter} ${phrase.phone_number}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.email,
            name: 'email',
            type: 'email',
            placeholder: `${phrase.enter} ${phrase.email}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.company_name,
            name: 'company_name',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.company_name}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.country,
            name: 'seller_type',
            class: 'col-md-6 col-12',
            type: 'select',
            options: [
                { show: 'USA Only', value: 'domestic' },
                { show: 'All other countries', value: 'international' },
            ],
            formik: formik,
        },
    ]

    return (
        <section className="container">
            <div className="registration d-flex justify-content-between">
                <div className="regBox">
                    <Link to="/">
                        <img className="brandLogo" src={logo} alt={`${siteName} logo`} />
                    </Link>
                    <h2 className="regTitle">
                        <span>{phrase.registration}</span>
                    </h2>
                    <form action="" onSubmit={formik.handleSubmit} ref={formRef}>
                        <div className={classes.root}>
                            <div>
                                <div>
                                    {completed ? (
                                        <div className="row">
                                            <div className="regSuccess">
                                                <h3>{phrase.youre_done}!</h3>
                                                <h5>{phrase.registerpagestatic_34}</h5>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            {Object.values(mapData(inputData))}
                                        </div>
                                    )}
                                    <div className="crActBtn">
                                        {completed ? (
                                            <Link to="/login">
                                                <PrimaryButton label={phrase.finish} />
                                            </Link>
                                        ) : (
                                            <PrimaryButton label={phrase.submit} type="submit" />
                                        )}
                                    </div>
                                    <div className="regMiscAction">
                                        <p>
                                            <span className="material-icons">arrow_back</span>
                                            <Link to="/login">{phrase.back_to_login}</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default SellerRegistration
