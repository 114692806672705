import React, { useContext, useEffect } from 'react'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { handleRedirectInternal } from '../../common/components'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import './Home.css'
import { siteName } from '../../Utils'
import CommonContext from '../../context/common/commonContext'

function Home() {
    const history = useHistory()
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    }

    const { language, phrase, getLangPhrase } = useContext(CommonContext)

    useEffect(() => {
        getLangPhrase(language)
    }, [language])

    return (
        <div className="home">
            <div className="homeBanner">
                <div className="homeInfo">
                    <img className="dividerLine" src="./assets/images/dividerLine.png" />
                    <Slider {...settings}>
                        <h4>{siteName + ' ' + phrase.homepage_scroll_one}</h4>
                        <h4>{phrase.homepage_scroll_two}</h4>
                        <h4>The {siteName + ' ' + phrase.homepage_scroll_three}</h4>
                        <h4>{phrase.homepage_scroll_four}</h4>
                    </Slider>
                    <img className="dividerLine" src="./assets/images/dividerLine.png" />
                </div>
                <div className="actButtons d-flex justify-content-center align-items-center">
                    <PrimaryButton
                        btnSize="large"
                        label={phrase.become_a_buyer}
                        iconRight={<img src="./assets/images/rightArrow.png" />}
                        onClick={() => handleRedirectInternal(history, `register`)}
                    />
                    <PrimaryButton
                        btnSize="large"
                        label={phrase.become_a_seller}
                        iconRight={<img src="./assets/images/rightArrow.png" />}
                        onClick={() => handleRedirectInternal(history, `register_seller`)}
                    />
                    <PrimaryButton
                        btnSize="large"
                        label={phrase.login}
                        iconRight={<img src="./assets/images/rightArrow.png" />}
                        onClick={() => handleRedirectInternal(history, `login`)}
                    />
                </div>
                <div className="productPreviewImages">
                    <img src="./assets/images/home-2.png" />
                    <img src="./assets/images/home-1.png" />
                </div>
            </div>
        </div>
    )
}

export default Home
