export class response {
    constructor(dispatch, RESPONSE_STATUS) {
        this.dispatch = dispatch
        this.RESPONSE_STATUS = RESPONSE_STATUS
    }

    async commonErrorResponse(from, msg) {
        this.dispatch({
            type: this.RESPONSE_STATUS,
            payload: {
                status: 'error',
                message: msg ? msg : 'Something went wrong!',
                type: 0,
                from: from,
            },
        })
    }

    async commonResponse(data, from) {
        if (data.status === 'success') {
            this.dispatch({
                type: this.RESPONSE_STATUS,
                payload: {
                    status: data.status,
                    message: data.message,
                    type: data.responseType,
                    data: data.responseData,
                    from: from,
                },
            })
            return
        }
        if (data.returnval === 2) {
            this.commonErrorResponse(from, data.msg)
        } else if (data.returnval === 4) {
            this.commonErrorResponse(from, data.msg)
        } else if (data.returnval === 5) {
            this.commonErrorResponse(from, data.msg)
        } else if (data.returnval === 3) {
            this.dispatch({
                type: this.RESPONSE_STATUS,
                payload: {
                    status: 'Success',
                    message: data.msg,
                    type: data.responseType,
                    data: data.responseData,
                    from: from,
                },
            })
        } else {
            this.commonErrorResponse(from)
        }
    }
}
