import React, { useState, useContext, useEffect } from 'react'
import './Login.css'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import CheckBox from '../../components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import { Button, Divider } from '@material-ui/core'
import { mapData, handleRedirectInternal } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import CommonContext from '../../context/common/commonContext'
import { logo, siteName } from '../../Utils'

const Login = () => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext
    const { phrase } = useContext(CommonContext)

    const { login, responseStatus, clearResponse, isAuthenticated } = authContext

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, '')
        }
    }, [isAuthenticated])
    const rememberedTokenLoc = localStorage.getItem('remembered')
    let [passwordShown, setPasswordShown] = useState(false)
    let [rememberMeChecked, setRememberMeChecked] = useState(rememberedTokenLoc ? true : false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const validationArray = Yup.object({
        email: Yup.string()
            .email(phrase.invalid_format)
            .required(`${phrase.enter} ${phrase.email_address}`),
        password: Yup.string().required(`${phrase.enter} ${phrase.password}`),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let payload = {
                username: values.email,
                password: values.password,
                site_id: global.site_id,
            }
            let data = await login(payload)
            if (data.returnval === 3) handleRedirectInternal(history, 'search')
            if (data.returnval == 5)
                handleRedirectInternal(history, `afterregister/${data.linkafter}`)
        },
    })

    const loginInfo = [
        {
            label: phrase.email_address,
            name: 'email',
            type: 'email',
            placeholder: `${phrase.enter} ${phrase.email_address}`,
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: phrase.password,
            name: 'password',
            type: passwordShown ? 'text' : 'password',
            placeholder: `${phrase.enter} ${phrase.password}`,
            class: 'col-12',
            formik: formik,
            endadornment: passwordShown ? (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility_off
                </span>
            ) : (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility
                </span>
            ),
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                setAlert(responseStatus.message, responseStatus.status)
                clearResponse()
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'search')
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (rememberMeChecked) {
            let rememberedUserToken = btoa(JSON.stringify(formik.values))
            localStorage.setItem('remembered', rememberedUserToken)
        } else {
            localStorage.removeItem('remembered')
        }
    }, [rememberMeChecked])
    useEffect(() => {
        if (rememberedTokenLoc) {
            let decriptedValue = atob(rememberedTokenLoc)
            decriptedValue = JSON.parse(decriptedValue)
            formik.setFieldValue('email', decriptedValue.email)
            formik.setFieldValue('password', decriptedValue.password)
        }
    }, [])
    const rememberMe = () => {
        setRememberMeChecked(!rememberMeChecked)
    }

    return (
        <div className="loginContainer">
            <div className="login">
                <Link to="/">
                    <img className="brandLogo" src={logo} alt={`${siteName} logo`} />
                </Link>
                <h2 className="loginTitle">
                    <span>{phrase.login}</span>
                </h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">{Object.values(mapData(loginInfo))}</div>
                    <div className="d-flex loginActBox justify-content-between align-items-center">
                        <CheckBox
                            label={phrase.remember_me}
                            onchange={rememberMe}
                            checked={rememberMeChecked}
                        />
                        <Link to="/forgot_password">{phrase.forgot_your_password}</Link>
                    </div>
                    <PrimaryButton label={phrase.login} type="submit" />
                </form>
                <div className="loginMiscAction">
                    <p>
                        {phrase.dont_have_an_account} <Link to="/register"> {phrase.sign_up} </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login
