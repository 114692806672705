import React, { useState, useEffect, useContext } from 'react'
import { Button } from '@material-ui/core'
import './Message.css'
import ActiveSellers from './ActiveSellers'
import SearchNav from '../../components/molecules/SearchNav'
import ActiveSubjects from './ActiveSubjects'
import Conversations from './Conversations'
import CommonContext from '../../context/common/commonContext'
import { apiCall } from '../../common/api'
import Drawer from '@material-ui/core/Drawer'

function Message() {
    let { getLangPhrase, language, phrase, getMsgUnreadCount } = useContext(CommonContext)
    const [parentMessageData, setParentMessageData] = useState({})
    const [sellerMessages, setSellerMessages] = useState({})
    const [conversations, setConversations] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [showSubjects, setShowSubjects] = useState(false)
    const [state, setState] = useState({
        left: false,
    })
    const [activeSeller, setActiveSeller] = useState({
        index: '',
        element: '',
    })
    const [activeProject, setActiveProject] = useState({
        index: '',
        element: '',
    })
    const [isArchive, setIsArchive] = useState(false)
    const [activeTab, setActiveTab] = useState()
    const [firstTab, setFirstTab] = useState()

    useEffect(() => {
        getLangPhrase(language)
        document.title = global.site_title + ' | Messages'
    }, [language])

    useEffect(async () => {
        setIsLoading(true)
        const result = await getParentMessageData()
        if (result) {
            setIsLoading(false)
        }
    }, [isArchive])

    useEffect(() => {
        if (firstTab) {
            setActiveTab(firstTab)
        }
    }, [firstTab])

    const getParentMessageData = async () => {
        let parentMessageFromData = new FormData()
        parentMessageFromData.append('archive', isArchive)
        const res = await apiCall('get', '', parentMessageFromData, '', 'messages')
        if (res.data.success) {
            setParentMessageData(res.data.data)
            return true
        }
    }

    const getProjectMessageData = async (seller_id) => {
        let projectMessagesFormData = new FormData()
        projectMessagesFormData.append('sellerid', seller_id)
        projectMessagesFormData.append('archive', isArchive)

        const res = await apiCall('post', '', projectMessagesFormData, '', 'searchprojectmessages')
        if (res.data.success) {
            setSellerMessages(res.data.data)
            return true
        } else {
            return false
        }
    }

    const getConverstionData = async (seller_id, project_id, unreadMessageCount) => {
        let actualMessagesFormData = new FormData()
        actualMessagesFormData.append('projectid', project_id)
        actualMessagesFormData.append('sellerid', seller_id)
        actualMessagesFormData.append('archive', isArchive)

        if (unreadMessageCount != '') {
            actualMessagesFormData.append('activemainusertunread', unreadMessageCount)
            actualMessagesFormData.append('activesellerunread', unreadMessageCount)
        }

        const res = await apiCall('post', '', actualMessagesFormData, '', 'searchactualmessages')
        if (res.data.success) {
            setConversations(res.data.data)
            return true
        } else {
            return false
        }
    }

    const sellerClickHandler = async (e, index, seller_id) => {
        e.persist()
        let result = getProjectMessageData(seller_id)
        if (result) {
            if (activeSeller.element !== undefined) {
                activeSeller.element.classList && activeSeller.element.classList.remove('active')
            }
            e.currentTarget.classList.add('active')

            setActiveSeller({
                index: index,
                element: e.currentTarget,
            })
        }
    }

    const projectClickHandler = async (e, index, seller_id, unreadMessageCount, project_id) => {
        e.persist()
        let result = getConverstionData(seller_id, project_id, unreadMessageCount)
        if (result) {
            reset(seller_id, project_id, unreadMessageCount)

            if (activeProject.element !== undefined) {
                activeProject.element.classList && activeProject.element.classList.remove('active')
            }
            e.currentTarget.classList.add('active')
            setActiveProject({
                index: index,
                element: e.currentTarget,
            })
        }
    }

    const handleMessageSubmit = async (message, seller_id, project_id, uploadedFile) => {
        let messageFormData = new FormData()
        if (uploadedFile) {
            let uploadFileFormData = new FormData()
            uploadFileFormData.append('ig_messages_upload', uploadedFile)

            const getFileNameRespone = await apiCall(
                'post',
                '',
                uploadFileFormData,
                '',
                'messages/addnewattach/1',
            )

            if (getFileNameRespone.data) {
                messageFormData.append('attachinsidename', getFileNameRespone.data.datumnew.name)
                messageFormData.append(
                    'attachorginalname',
                    getFileNameRespone.data.datumnew.originalname,
                )
            }
        }
        messageFormData.append('sellermsgpost', seller_id)
        messageFormData.append('projectmsgpost', project_id)
        messageFormData.append('message', message)
        messageFormData.append('status', 'open')
        messageFormData.append('fromadmin', 0)

        if (uploadedFile) {
            //Save uploaded File
            const res = await apiCall(
                'post',
                '',
                messageFormData,
                '',
                'messages/saveattachementmessage',
            )
            //Then Save Message
            if (res.data.success) {
                const res = await apiCall('post', '', messageFormData, '', 'messages/savemessage')
                if (res.data.success) {
                    reset(seller_id, project_id, '')
                }
            }
        } else {
            //Save Message
            const res = await apiCall('post', '', messageFormData, '', 'messages/savemessage')
            if (res.data.success) {
                reset(seller_id, project_id, '')
            }
        }
    }

    const handleMessageDelete = async (message_id, seller_id, project_id) => {
        let deleteMessageFormData = new FormData()
        deleteMessageFormData.append('id', message_id)

        const res = await apiCall('post', '', deleteMessageFormData, '', 'messages/deletemessage')
        if (res.data.success) {
            reset(seller_id, project_id, '')
        }
    }

    const assignActiveTab = (element) => {
        setFirstTab(element)
    }

    const toggleTab = (e, archiveState) => {
        setIsArchive(archiveState)
        if (activeTab) {
            activeTab.classList.remove('active')
        }
        e.currentTarget.classList.add('active')
        setActiveTab(e.currentTarget)
    }

    const handleActiveTabClick = (e) => {
        toggleTab(e, false)
    }

    const handleArchiveTabClick = (e) => {
        toggleTab(e, true)
    }

    const handleUnarchiveButtonClick = async (seller_id, project_id) => {
        let unarchiveFormData = new FormData()
        unarchiveFormData.append('sellerid', seller_id)
        unarchiveFormData.append('projectid', project_id)
        unarchiveFormData.append('archive', true)

        const res = await apiCall('post', '', unarchiveFormData, '', 'messages/archivemessages')
        if (res.data.success) {
            firstTab.click()
        }
    }

    const handleArchiveButtonClick = async (seller_id, project_id) => {
        let archiveFormData = new FormData()
        archiveFormData.append('sellerid', seller_id)
        archiveFormData.append('projectid', project_id)

        const res = await apiCall('post', '', archiveFormData, '', 'messages/archivemessages')
        if (res.data.success) {
            if (project_id == sellerMessages.projectmessages[activeProject.index].project_id) {
                reset(seller_id, project_id, '')
            } else {
                reset(seller_id, '', '')
            }
        }
    }

    const reset = (seller_id, project_id, unreadMessageCount) => {
        getParentMessageData()
        getProjectMessageData(seller_id)
        if (project_id != '') {
            getConverstionData(seller_id, project_id, '')
        }
        if (unreadMessageCount > 0) {
            getMsgUnreadCount()
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    return (
        <>
            <SearchNav handleKeyDown={() => {}} />
            <div className="messages">
                <div className="container-lg">
                    <div className="messageTop d-flex justify-content-between align-items-center">
                        <h2>{phrase.all_messages}</h2>
                        <div className="d-flex justify-content-end">
                            <Button
                                className="active"
                                onClick={handleActiveTabClick}
                                ref={(element) => assignActiveTab(element)}
                            >
                                {phrase.active + '(' + parentMessageData.activemsgcount + ')'}
                            </Button>
                            <Button onClick={handleArchiveTabClick}>
                                {phrase.archive + '(' + parentMessageData.archivemsgcount + ')'}
                            </Button>
                        </div>
                    </div>
                    <div className="messageCnt">
                        {isArchive ? (
                            parentMessageData.archivemsgcount > 0 ? (
                                <>
                                    <div className="messageHead d-flex justify-content-between align-items-center flex-wrap">
                                        <Button
                                            className="toggleSellers"
                                            onClick={toggleDrawer('left', true)}
                                        >
                                            <span className="material-icons">people</span> Show
                                            Sellers
                                        </Button>
                                        <h4>{phrase.sellers}</h4>
                                        <h5>
                                            {phrase.conversations + ' - '}
                                            <span>
                                                {sellerMessages.projectmessages &&
                                                activeProject.index !== ''
                                                    ? sellerMessages.projectmessages[
                                                          activeProject.index
                                                      ].title
                                                    : ''}
                                            </span>
                                        </h5>
                                        <h6>
                                            {sellerMessages.projectmessages &&
                                            activeProject.index !== ''
                                                ? sellerMessages.projectmessages[
                                                      activeProject.index
                                                  ].conversationcount +
                                                  ' ' +
                                                  phrase.conversations
                                                : phrase.conversations}
                                        </h6>
                                    </div>
                                    <div className="messageBody d-flex justify-content-start">
                                        <ActiveSellers
                                            sellers={parentMessageData.messagesforusers}
                                            onClick={sellerClickHandler}
                                            isArchive={isArchive}
                                        />
                                        <ActiveSubjects
                                            sellerMessages={sellerMessages}
                                            phrase={phrase}
                                            onClick={projectClickHandler}
                                            isArchive={isArchive}
                                            handleUnarchiveButtonClick={handleUnarchiveButtonClick}
                                            handleArchiveButtonClick={handleArchiveButtonClick}
                                        />
                                        <Conversations
                                            conversations={conversations}
                                            handleMessageSubmit={handleMessageSubmit}
                                            handleMessageDelete={handleMessageDelete}
                                            phrase={phrase}
                                        />
                                    </div>
                                </>
                            ) : isLoading ? (
                                <div className="nomessage">Loading...</div>
                            ) : (
                                <div className="nomessage">{phrase.no_new_messages}</div>
                            )
                        ) : parentMessageData.activemsgcount > 0 ? (
                            <>
                                <div className="messageHead d-flex justify-content-between align-items-center flex-wrap">
                                    <Button
                                        className="toggleSellers"
                                        onClick={toggleDrawer('left', true)}
                                    >
                                        <span className="material-icons">people_alt</span> Show
                                        Sellers
                                    </Button>
                                    <h4>{phrase.sellers}</h4>
                                    <h5>
                                        {phrase.conversations + ' - '}
                                        <span>
                                            {sellerMessages.projectmessages &&
                                            activeProject.index !== ''
                                                ? sellerMessages.projectmessages[
                                                      activeProject.index
                                                  ].title
                                                : ''}
                                        </span>
                                    </h5>
                                    <h6>
                                        {sellerMessages.projectmessages &&
                                        activeProject.index !== ''
                                            ? sellerMessages.projectmessages[activeProject.index]
                                                  .conversationcount +
                                              ' ' +
                                              phrase.conversations
                                            : phrase.conversations}
                                    </h6>
                                </div>
                                <div className="messageBody d-flex justify-content-start">
                                    <ActiveSellers
                                        sellers={parentMessageData.messagesforusers}
                                        onClick={sellerClickHandler}
                                        isArchive={isArchive}
                                    />
                                    <ActiveSubjects
                                        sellerMessages={sellerMessages}
                                        phrase={phrase}
                                        onClick={projectClickHandler}
                                        isArchive={isArchive}
                                        handleUnarchiveButtonClick={handleUnarchiveButtonClick}
                                        handleArchiveButtonClick={handleArchiveButtonClick}
                                    />
                                    <Conversations
                                        conversations={conversations}
                                        handleMessageSubmit={handleMessageSubmit}
                                        handleMessageDelete={handleMessageDelete}
                                        phrase={phrase}
                                    />
                                </div>
                            </>
                        ) : isLoading ? (
                            <div className="nomessage">Loading...</div>
                        ) : (
                            <div className="nomessage">{phrase.no_new_messages}</div>
                        )}
                    </div>
                </div>
            </div>
            <Drawer
                anchor="left"
                open={state['left']}
                className="msgDrawer"
                onClose={toggleDrawer('left', false)}
            >
                <h4 className="msgHead">
                    {showSubjects === true ? 'Active Conversations' : 'Active Sellers'}
                </h4>
                {showSubjects === true ? (
                    <span>
                        <Button className="backSellerBtn" onClick={() => setShowSubjects(false)}>
                            <span className="material-icons">arrow_back</span>Back to Sellers
                        </Button>
                        <ActiveSubjects
                            sellerMessages={sellerMessages}
                            phrase={phrase}
                            onClick={projectClickHandler}
                            isArchive={isArchive}
                            handleUnarchiveButtonClick={handleUnarchiveButtonClick}
                            handleArchiveButtonClick={handleArchiveButtonClick}
                        />
                    </span>
                ) : (
                    <span onClick={() => setShowSubjects(true)}>
                        <ActiveSellers
                            sellers={parentMessageData.messagesforusers}
                            onClick={sellerClickHandler}
                            isArchive={isArchive}
                        />
                    </span>
                )}
            </Drawer>
        </>
    )
}

export default Message
