import React, { useState, useContext, useEffect } from 'react'
import './ResetPassword.css'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import CheckBox from '../../components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import { Button, Divider } from '@material-ui/core'
import { mapData, handleRedirectInternal } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AlertContext from '../../context/alert/alertContext'
import CommonContext from '../../context/common/commonContext'
import UserContext from '../../context/user/userContext'
import { logo, siteName } from '../../Utils'

const ForgotPassword = (props) => {
    const history = useHistory()

    const { setAlert } = useContext(AlertContext)
    const { phrase } = useContext(CommonContext)
    const { decodeResetPasswordToken, updateUserPasswordUnauth } = useContext(UserContext)

    const rememberedTokenLoc = localStorage.getItem('remembered')
    const [userInfo, setUserInfo] = useState({})
    let [passwordShown, setPasswordShown] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    useEffect(async () => {
        const res = await decodeResetPasswordToken(props.match.params.token)
        setUserInfo(res)
    }, [])

    const validationArray = Yup.object({
        password: Yup.string().required(phrase.required_landline),
        // confirm_password: Yup.string().when('password', {
        //     is: (val) => (val && val.length > 0 ? true : false),
        //     then: Yup.string().oneOf([Yup.ref('password')], 'Password Mismatch'),
        // }),
        confirm_password: Yup.string()
            .required(`${phrase.confirm} ${phrase.password}`)
            .oneOf([Yup.ref('password'), null], phrase.this_value_should_be_the_same),
    })

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let passwordResetFormDate = new FormData()
            passwordResetFormDate.append('emailid', userInfo.emailid)
            passwordResetFormDate.append('email_encry', userInfo.emailency)
            passwordResetFormDate.append('userid', userInfo.user_id)
            passwordResetFormDate.append('password', values['password'])
            passwordResetFormDate.append('new_password1', values['password'])
            passwordResetFormDate.append('site_id', global.site_id)

            const res = await updateUserPasswordUnauth(passwordResetFormDate)
            if (res) {
                history.push('/login')
                setAlert('Password Change Successful', 'success')
            }
        },
    })

    const loginInfo = [
        {
            label: phrase.new_password,
            name: 'password',
            autoFocus: true,
            type: passwordShown ? 'text' : 'password',
            placeholder: phrase.new_password,
            class: 'col-12',
            formik: formik,
            endadornment: passwordShown ? (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility_off
                </span>
            ) : (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility
                </span>
            ),
        },
        {
            label: phrase.confirm_password,
            name: 'confirm_password',
            type: 'password',
            placeholder: phrase.confirm_password,
            class: 'col-12',
            formik: formik,
        },
    ]

    return (
        <div className="loginContainer">
            <div className="login">
                <Link to="/">
                    <img className="brandLogo" src={logo} alt={`${siteName} logo`} />
                </Link>
                <h2 className="loginTitle">
                    <span>{phrase.password_reset}</span>
                </h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">{Object.values(mapData(loginInfo))}</div>
                    <PrimaryButton label={phrase.submit} type="submit" />
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword
