import React, { useEffect, useContext, useState, useRef } from 'react'
import openSocket from 'socket.io-client'
import { Route, withRouter, Switch, useHistory } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Login from '../views/Login'
import AutoLogin from '../views/AutoLogin'
import Header from '../components/molecules/Header'
import Maintainance from '../components/molecules/Maintainance'
import Home from '../views/Home'
import Footer from '../components/molecules/Footer'
import AuthContext from '../context/auth/authContext'
import AlertContext from '../context/alert/alertContext'
import CommonContext from '../context/common/commonContext'
import csc from 'country-state-city'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'
import UnsubscribeEmail from '../views/UnsubscribeEmail'
import Search from '../views/Search'
import UnAuthHeader from '../components/molecules/Header/UnAuthHeader'
import Registration from '../views/Registration'
import SellerRegistration from '../views/SellerRegistration'
import StaticPage from '../views/StaticPages'
import ProductView from '../views/ProductView'
import Dashboard from '../views/Dashboard'
import Message from '../views/Message'
import MyAccount from '../views/MyAccount'
import MyPreferences from '../views/MyPreferences'
import PriceLock from '../views/PriceLock'
import Support from '../views/Support'
import Sitemap from '../views/Sitemap'
import Invoice from '../views/Invoice'
import BuynowConfirm from '../views/BuynowConfirm'
import { handleRedirectInternal } from '../common/components'
import ErrorPage from '../views/ErrorPage'

const Routes = (props) => {
    const authContext = useContext(AuthContext)
    const { user, loadUser, logout, isAuthenticated } = authContext
    const [maintainanceMode, setMatainanceMode] = useState(false)
    const { setAlert } = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const {
        //getGlobalVariable,
        setUSState,
        getNonLoginToken,
        setAllCountries,
        phrase,
        getLangPhrase,
        language,
    } = commonContext
    const [readyToRender, setReady] = useState(false)
    const history = useHistory()

    const userRef = useRef(user)

    useEffect(() => {
        userRef.current = user
    })

    useEffect(() => {
        // ready to render after lang phrases are loaded
        if (Object.keys(phrase).length > 0) {
            setReady(true)
        }
    }, [phrase])

    useEffect(() => {
        getLangPhrase(language)
    }, [language])

    useEffect(() => {
        if (global.maintainance_mode == true && maintainanceMode == false) {
            setMatainanceMode(true)
        } else if (global.maintainance_mode == false) {
            setMatainanceMode(false)
        }
    }, [global.maintainance_mode])

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('userDeactivate', (data) => {
            // current user deactivated, logout the user
            if (userRef.current.id == data.userId) {
                logout()
                setAlert('Your account have been deactivated. You were forced to log out.', 'error')
            }
        })
        if (localStorage.token) {
            loadUser()
        }
        getNonLoginToken()
        //getGlobalVariable()
        if (Object.keys(phrase).length == 0) {
            getLangPhrase(language)
        }
        const USStates = []
        const allCountries = csc.getAllCountries().map((ele) => {
            return { value: ele.id, show: ele.name }
        })
        csc.getStatesOfCountry('231').map((lot) => {
            USStates.push({ value: lot.name, show: lot.name })
        })
        setAllCountries(allCountries)
        setUSState(USStates)
        return () => {
            socket.off('userDeactivate', (data) => {})
            socket.disconnect()
        }
    }, [])

    let header = null
    let footer = null
    if (
        props.location.pathname === '/login' ||
        props.location.pathname === '/register' ||
        props.location.pathname === '/forgot_password' ||
        props.location.pathname.startsWith('/reset_password/')
    ) {
        header = <UnAuthHeader />
    } else {
        header = <Header />
        footer = <Footer />
    }

    if (isAuthenticated && props.location.pathname === '/') {
        handleRedirectInternal(history, 'search')
    }
    return (
        <>
            {readyToRender ? (
                <>
                    {header}
                    {maintainanceMode ? (
                        <Maintainance />
                    ) : (
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <PrivateRoute exact path="/" component={Search} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/auto_login/:email/:token" component={AutoLogin} />
                            <Route exact path="/forgot_password" component={ForgotPassword} />
                            <Route exact path="/reset_password/:token" component={ResetPassword} />
                            <Route exact path="/unsubscribe/:token" component={UnsubscribeEmail} />
                            <Route exact path="/afterregister/:token" component={Registration} />
                            <Route exact path="/register" component={Registration} />
                            <Route exact path="/register_seller" component={SellerRegistration} />
                            <PrivateRoute exact path="/search" component={Search} />
                            <PrivateRoute exact path="/product/view/:id" component={ProductView} />
                            <PrivateRoute
                                exact
                                path="/product/buynow/:id"
                                component={BuynowConfirm}
                            />
                            <PrivateRoute exact path="/dashboard" component={Dashboard} />
                            <PrivateRoute exact path="/message" component={Message} />
                            <PrivateRoute exact path="/my_account" component={MyAccount} />
                            <PrivateRoute exact path="/my_preferences" component={MyPreferences} />
                            <PrivateRoute exact path="/price_lock" component={PriceLock} />
                            <Route exact path="/invoice/:id" component={Invoice} />
                            <Route exact path="/support" component={Support} />
                            <Route exact path="/sitemap" component={Sitemap} />
                            <Route exact path="/about">
                                <StaticPage page="about" />
                            </Route>
                            <Route exact path="/privacy">
                                <StaticPage page="privacy" />
                            </Route>
                            <Route exact path="/privacy-shield">
                                <StaticPage page="privacy_shield" />
                            </Route>
                            <Route exact path="/terms">
                                <StaticPage page="terms" />
                            </Route>
                            <PrivateRoute exact path="/dashboard/:type" component={Dashboard} />
                            <Route exact path="*" component={ErrorPage} />
                        </Switch>
                    )}

                    {footer}
                </>
            ) : null}
        </>
    )
}
export default withRouter(Routes)
