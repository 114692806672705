import React, { useState, useContext } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import CustomInput from '../../components/atoms/Inputs/CustomInput'
// context
import CommonContext from '../../context/common/commonContext'

const CurrencyConverter = ({ product }) => {
    const { phrase } = useContext(CommonContext)
    const [value, setValue] = useState('')
    const [usdRate, setUsdRate] = useState(1)
    const [otherCurrencyRate, setOtherCurrencyRate] = useState(0)
    const [showConverter, setShowConverter] = useState(false)
    const [currencyData, setCurrencyData] = useState({})

    // choose currency option changed
    const handleChange = (event) => {
        console.log('handle chnage_______', event.target)

        setValue(event.target.value)
        if (event.target.value !== '') {
            // get foreign currency data
            let currIndex = product.currenciesavailable.findIndex((ele) => {
                return ele.id == event.target.value
            })
            setOtherCurrencyRate(parseInt(product.currenciesavailable[currIndex].current_value))
            setCurrencyData(product.currenciesavailable[currIndex])
            setShowConverter(true)
        }
    }
    // calculate conversion
    const convertCurrency = (e, from) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '')
        if (onlyNums != '' && onlyNums != null) {
            if (from == 'USD') {
                setUsdRate(onlyNums)
                setOtherCurrencyRate(
                    parseInt(parseFloat(currencyData.current_value) * parseFloat(onlyNums)),
                )
            } else {
                console.log(
                    'NOT USD______',
                    parseInt(parseFloat(parseFloat(onlyNums) / currencyData.current_value)),
                )
                setUsdRate(parseInt(parseFloat(parseFloat(onlyNums) / currencyData.current_value)))
                setOtherCurrencyRate(onlyNums)
            }
        } else {
            setUsdRate('')
            setOtherCurrencyRate('')
        }
    }

    return (
        <div className="row">
            <div className="col-md-3">
                <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    displayEmpty
                    value={value}
                    onChange={handleChange}
                >
                    <MenuItem value="" disabled>
                        {phrase.choose_currency}
                    </MenuItem>
                    {product.currenciesavailable.map((currency) => {
                        return (
                            <MenuItem value={currency.id}>
                                {currency.country_name} ({currency.country_exactcode})
                            </MenuItem>
                        )
                    })}
                </Select>
            </div>
            {showConverter ? (
                <div className="col-md-5">
                    <div className="row">
                        <div className="col-sm-6">
                            <CustomInput
                                value={usdRate}
                                onChange={(e) => convertCurrency(e, 'USD')}
                                startadornment={<a style={{ marginRight: '5px' }}>USD</a>}
                            />
                        </div>
                        <div className="col-sm-6">
                            <CustomInput
                                value={otherCurrencyRate}
                                onChange={(e) => convertCurrency(e, 'NOT USD')}
                                startadornment={
                                    <a style={{ marginRight: '5px' }}>
                                        {currencyData.country_exactcode}
                                    </a>
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <b>{phrase.exchange_rate}: </b>
                            &nbsp; 1 USD = &nbsp;
                            {currencyData.current_value + ' ' + currencyData.country_exactcode}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default CurrencyConverter
