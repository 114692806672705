import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

// Components
import Invoice from '../../components/molecules/Invoice/'

// Common Function
import { apiCall } from '../../common/api'
import { handleRedirectInternal } from '../../common/components'

//context
import AuthContext from '../../context/auth/authContext'

// css
import './invoice.css'

const ViewInvoice = (props) => {
    const history = useHistory()
    // state
    const [invoiceData, setInvoiceData] = useState({})
    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (props.match.params.id) {
            console.log('params id: ', props.match.params.id)
            document.title = `${global.site_title} | Invoice`
            getInvoiceData(props.match.params.id)
        }
    }, [])

    const getInvoiceData = (id) => {
        apiCall('get', `invoice/${id}`, '', '', 'product')
            .then((results) => {
                let { data } = results
                console.log('GOT INVOICE DATA: ', data)
                if (data.status == 'success' && user.id == data.data.invoicedata.buyeruser_id) {
                    console.log('INVOICE: ', data.data.invoicedata)
                    data.data.invoicedata.lineitem = data.data.invoicelineitem
                    setInvoiceData(data.data.invoicedata)
                } else {
                    history.goBack()
                }
            })
            .catch((err) => {
                handleRedirectInternal(history, 'search')
            })
    }

    return (
        <>
            {Object.keys(invoiceData).length > 0 ? (
                <>
                    <div className="d-flex justify-content-start topHeader">
                        <Button className="backBtn my-2" onClick={() => history.goBack()}>
                            <span className="material-icons">arrow_back</span>Back
                        </Button>
                    </div>
                    <Invoice data={invoiceData} />
                </>
            ) : null}
        </>
    )
}

export default ViewInvoice
