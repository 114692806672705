import React, { useReducer } from 'react'
import LoaderContext from './loader.context'
import LoaderReducer from './loader.reducer'
import { STATICPAGELOADER } from './loader.types'
const LoaderState = (props) => {
    const initialState = {
        isStaticPageLoad: false,
    }
    const [state, dispatch] = useReducer(LoaderReducer, initialState)
    const setStaticPageload = (value) => {
        dispatch({
            type: STATICPAGELOADER,
            payload: value,
        })
    }
    return (
        <LoaderContext.Provider
            value={{
                isStaticPageLoad: state,
                setStaticPageload,
            }}
        >
            {props.children}
        </LoaderContext.Provider>
    )
}
export default LoaderState
