import React, { useReducer } from 'react'
import AutoPltContext from './autopltContext'
import AutoPltReducer from './autopltReducer'
import axios from 'axios'
import { response } from '../common'

import { CLEAR_RESPONSE, RESPONSE_STATUS } from './autopltTypes'

const AutoPltState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AutoPltReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addCustomer = async (formData) => {
        try {
            try {
                const URL = 'https://api2.autopilothq.com/v1/contact'
                const data = {
                    contact: formData,
                }
                const config = {
                    headers: {
                        authorization: null,
                        ipaddress: null,
                        'content-type': 'application/json',
                        autopilotapikey: 'c655265afd50419cb1cf5c16b8e4430d',
                    },
                }
                const res = await axios.post(`${URL}`, data, config)
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: 'success',
                        message: 'Subscribed to newsletter successfully',
                        from: 'addCustomer',
                    },
                })
            } catch (err) {
                resp.commonErrorResponse('addCustomer')
            }
        } catch (err) {
            resp.commonErrorResponse('addCustomer')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <AutoPltContext.Provider
            value={{
                responseStatus: state.responseStatus,
                clearResponse,
                addCustomer,
            }}
        >
            {props.children}
        </AutoPltContext.Provider>
    )
}

export default AutoPltState
