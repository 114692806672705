import React, { useContext, useEffect, useState } from 'react'
import CheckBox from '../../components/atoms/CheckBox'
import '../Registration/Registration.css'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import UserContext from '../../context/user/userContext'
import CommonContext from '../../context/common/commonContext'
import AlertContext from '../../context/alert/alertContext'
import './MyPreferences.css'

function MyPreferences() {
    const { getUserPreferences, userPreferences, updateUserPreferences } = useContext(UserContext)
    const { language, phrase, getLangPhrase, getSitemap, staticPages } = useContext(CommonContext)
    const { setAlert } = useContext(AlertContext)
    const [regionStatus, setRegionStatus] = useState({
        region_lang_1: false,
        region_lang_3: false,
        region_lang_4: false,
        region_lang_5: false,
        region_lang_6: false,
        region_lang_7: false,
    })

    const [RadioOptions, setRadioOptions] = useState([])
    const [userPrefState, setUserPrefState] = useState([])

    useEffect(() => {
        // category loaded
        if (staticPages.categories) {
            setRadioOptions(
                staticPages.categories.map((cat) => {
                    return {
                        id: cat.id,
                        value: cat.id,
                        description: phrase[cat.language_var] ? phrase[cat.language_var] : cat.name,
                        name: cat.name,
                        checked() {
                            return userPrefState.indexOf(cat.id.toString()) !== -1
                        },
                    }
                }),
            )
        }
    }, [staticPages, userPrefState])

    useEffect(() => {
        document.title = global.site_title + ' | My Preferences'
        getUserPreferences()
        getLangPhrase(language)
    }, [language])

    useEffect(() => {
        if (userPreferences.interestinarray) {
            // get categories
            getSitemap()
            setUserPrefState(userPreferences.interestinarray)

            setRegionStatus({
                region_lang_1: userPreferences.interestinregionarray.includes('1'),
                region_lang_3: userPreferences.interestinregionarray.includes('3'),
                region_lang_4: userPreferences.interestinregionarray.includes('4'),
                region_lang_5: userPreferences.interestinregionarray.includes('5'),
                region_lang_6: userPreferences.interestinregionarray.includes('6'),
                region_lang_7: userPreferences.interestinregionarray.includes('7'),
            })
        }
    }, [userPreferences])

    const preferencesClickHandler = (e) => {
        console.log('clicked::: ', e.target.value)
        let catIndex = userPrefState.indexOf(e.target.value.toString())
        let tempArr = [...userPrefState]
        // checked\
        if (catIndex !== -1) {
            tempArr.splice(catIndex, 1)
            setUserPrefState(tempArr)
        } else {
            console.log('not checked')
            tempArr.push(e.target.value)
            console.log(tempArr)
            setUserPrefState(tempArr)
        }
    }

    const regionClickHandler = (e) => {
        switch (e.target.value) {
            case '1':
                setRegionStatus({
                    ...regionStatus,
                    region_lang_1: !regionStatus.region_lang_1,
                })
                break
            case '3':
                setRegionStatus({
                    ...regionStatus,
                    region_lang_3: !regionStatus.region_lang_3,
                })
                break
            case '4':
                setRegionStatus({
                    ...regionStatus,
                    region_lang_4: !regionStatus.region_lang_4,
                })
                break
            case '5':
                setRegionStatus({
                    ...regionStatus,
                    region_lang_5: !regionStatus.region_lang_5,
                })
                break
            case '6':
                setRegionStatus({
                    ...regionStatus,
                    region_lang_6: !regionStatus.region_lang_6,
                })
                break
            case '7':
                setRegionStatus({
                    ...regionStatus,
                    region_lang_7: !regionStatus.region_lang_7,
                })
                break
        }
    }

    const saveClickHandler = async (e) => {
        let preferenceFormData = new FormData()
        RadioOptions.map((preference) => {
            if (preference.checked()) {
                preferenceFormData.append('interestin', preference.value)
            }
        })
        if (regionStatus.region_lang_1) {
            preferenceFormData.append('interestinRegion', 1)
        }
        if (regionStatus.region_lang_3) {
            preferenceFormData.append('interestinRegion', 3)
        }
        if (regionStatus.region_lang_4) {
            preferenceFormData.append('interestinRegion', 4)
        }
        if (regionStatus.region_lang_5) {
            preferenceFormData.append('interestinRegion', 5)
        }
        if (regionStatus.region_lang_6) {
            preferenceFormData.append('interestinRegion', 6)
        }
        if (regionStatus.region_lang_7) {
            preferenceFormData.append('interestinRegion', 7)
        }

        for (var pair of preferenceFormData.entries()) {
            console.log(pair[0] + ', ' + pair[1])
        }

        const res = await updateUserPreferences(preferenceFormData)
        if (res) {
            setAlert(phrase.preference_updated, 'success')
        }
    }

    const selectAllClickHandler = () => {
        setRegionStatus({
            region_lang_1: true,
            region_lang_3: true,
            region_lang_4: true,
            region_lang_5: true,
            region_lang_6: true,
            region_lang_7: true,
        })
    }

    const deselectAllClickHandler = () => {
        setRegionStatus({
            region_lang_1: false,
            region_lang_3: false,
            region_lang_4: false,
            region_lang_5: false,
            region_lang_6: false,
            region_lang_7: false,
        })
    }

    return (
        <div className="container-lg">
            <h2 className="mpTitle">{phrase.my_preferences}</h2>
            <div className="row mpCnt">
                <div className="regCategories mb-4 col-12">
                    {RadioOptions.map((data, index) => (
                        <div className="categorySelect" key={index}>
                            <CheckBox
                                name={data.name}
                                label={data.description}
                                value={data.value}
                                checked={data.checked()}
                                onchange={preferencesClickHandler}
                            />
                        </div>
                    ))}
                </div>
                <h3 className="rgTitle">{phrase.preferred_region}</h3>
                <div className="prefActBtn d-flex justify-content-center align-items-center flex-wrap">
                    <SecondaryButton label="Deselect All" onClick={deselectAllClickHandler} />
                    <PrimaryButton label="Select All" onClick={selectAllClickHandler} />
                </div>

                <div className="regCategories mb-4 col-12">
                    {userPreferences.regionlist &&
                        userPreferences.regionlist.map((data, index) => (
                            <div key={index}>
                                <CheckBox
                                    name={data.region_eng}
                                    label={phrase[data.language_var]}
                                    value={data.regionid}
                                    checked={regionStatus[data.language_var]}
                                    onchange={regionClickHandler}
                                />
                            </div>
                        ))}
                </div>

                {/* <div className="col-md-4 offset-md-4 col-12 mt-4">
                    <CustomSelect label="Preferred region" />
                </div> */}
                <div className="col-md-2 offset-md-5 col-12 mb-4">
                    <PrimaryButton label={phrase.save} onClick={saveClickHandler} />
                </div>
            </div>
        </div>
    )
}

export default MyPreferences
