import React, { useContext, useState, useEffect } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import '../Login/Login.css'
import { mapData, handleRedirectInternal } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import AlertContext from '../../context/alert/alertContext'
import UserContext from '../../context/user/userContext'
import { Link } from 'react-router-dom'
import { logo, siteName } from '../../Utils'
import CommonContext from '../../context/common/commonContext'

const ForgotPassword = () => {
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { phrase } = useContext(CommonContext)

    const { forgotPassword, responseStatus, clearResponse } = userContext
    const { setAlert } = alertContext

    const history = useHistory()
    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required(phrase.this_is_required),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            values['site_id'] = global.site_id
            forgotPassword(values)
        },
    })

    const forgotValues = [
        {
            label: phrase.email_address,
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'forgotPassword') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    return (
        <div className="loginContainer">
            <div className="login forgotPassword">
                <Link to="/">
                    <img className="brandLogo" src={logo} alt={`${siteName} logo`} />
                </Link>
                <h2 className="loginTitle">
                    <span>{phrase.password_reset}</span>
                </h2>
                <h4>{phrase.password_reset_message}</h4>
                <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                    <div className="row">{Object.values(mapData(forgotValues))}</div>
                    <PrimaryButton type="submit" label={phrase.send} />
                </form>
                <div className="loginMiscAction">
                    <p>
                        <span className="material-icons">arrow_back</span>
                        <Link to="/login">{phrase.back_to_login}</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword
