import * as Yup from 'yup'
import { apiCall } from '../../common/api'

export const step1InitialValues = () => {
    return {
        first_name: '',
        last_name: '',
        company_name: '',
        business_type: '',
        username: '',
        language: '',
        phone: '',
        landline: '',
        email: '',
        confirm_email: '',
        password: '',
        password1: '',
    }
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const step1ValidationSchema = (phrase) => {
    return Yup.object({
        first_name: Yup.string().required(`${phrase.enter} ${phrase.first_name}`),
        last_name: Yup.string().required(`${phrase.enter} ${phrase.last_name}`),
        company_name: Yup.string().required(`${phrase.enter} ${phrase.company_name}`),
        business_type: Yup.string().required(`${phrase.enter} ${phrase.type_of_business}`),
        username: Yup.string().required(`${phrase.enter} ${phrase.username}`),
        language: Yup.string().required(`${phrase.select} ${phrase.language}`),
        //phone: Yup.string().required(phrase.invalid_number),
        //.matches(phoneRegExp, phrase.invalid_number),
        landline: Yup.string().required(phrase.invalid_number),
        //Yup.string().matches(phoneRegExp, phrase.invalid_number),
        email: Yup.string().required(`${phrase.enter} ${phrase.email}`),
        confirm_email: Yup.string()
            .required(`${phrase.confirm} ${phrase.email}`)
            .oneOf([Yup.ref('email'), null], phrase.this_value_should_be_the_same),
        password: Yup.string().required(`${phrase.enter} ${phrase.password}`),
        password1: Yup.string()
            .required(`${phrase.confirm} ${phrase.password}`)
            .oneOf([Yup.ref('password'), null], phrase.this_value_should_be_the_same),
    })
}

export const step1Data = (formik, phrase, languageArr) => {
    return [
        {
            label: phrase.first_name,
            name: 'first_name',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.first_name}`,
            class: 'col-md-6 col-12',
            variant: 'outlined',
            autoFocus: true,
            formik: formik,
        },
        {
            label: phrase.last_name,
            name: 'last_name',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.last_name}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.company_name,
            name: 'company_name',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.company_name}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.type_of_business,
            name: 'business_type',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.type_of_business}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.username,
            name: 'username',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.username}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.language,
            placeholder: `${phrase.select} ${phrase.language}`,
            class: 'col-md-6 col-12',
            type: 'select',
            options: languageArr,
            name: 'language',
            formik: formik,
        },
        {
            label: phrase.phone_number,
            name: 'phone',
            type: 'countrySelect',
            defaultCountry: 'us',
            regions: 'america',
            placeholder: `${phrase.enter} ${phrase.phone_number}`,
            class: 'col-md-6 col-12',
            formik: formik,
            messageBelow: `${phrase.required_phone}`,
        },
        {
            label: phrase.landline_number,
            name: 'landline',
            type: 'countrySelect',
            defaultCountry: 'us',
            regions: 'america',
            placeholder: `${phrase.enter} ${phrase.landline_number}`,
            class: 'col-md-6 col-12',
            formik: formik,
            messageBelow: `${phrase.required_landline}`,
        },
        {
            label: phrase.email,
            name: 'email',
            type: 'email',
            placeholder: `${phrase.enter} ${phrase.email}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: `${phrase.confirm} ${phrase.email}`,
            name: 'confirm_email',
            type: 'email',
            placeholder: `${phrase.confirm} ${phrase.email}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.password,
            name: 'password',
            type: 'password',
            placeholder: `${phrase.enter} ${phrase.password}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: `${phrase.confirm} ${phrase.password}`,
            name: 'password1',
            type: 'password',
            placeholder: `${phrase.confirm} ${phrase.password}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
    ]
}

export const step2InitialValues = () => {
    return {
        city: '',
        counntry: '',
        state: '',
        zip_code: '',
        street_address: '',
    }
}
export const step2ValidationSchema = (phrase) => {
    return Yup.object({
        city: Yup.string().required(`${phrase.enter} ${phrase.city}`),
        counntry: Yup.string().required(`${phrase.enter} ${phrase.country}`),
        state: Yup.string()
            .test('checkUndefined', `${phrase.enter} ${phrase.state}`, (value) => {
                console.log('state validation::: ', value)
                if (typeof value === 'undefined') {
                    console.log('UNDEFINED')
                    return false
                }
                return true
            })
            .required(`${phrase.enter} ${phrase.state}`),
        zip_code: Yup.string()
            .matches(/^([0-9]+-)*[0-9]+$/, `${phrase.invalid_format} ${phrase.zip}`)
            .min(4, 'Min 4 Characters')
            .max(10, 'Max 10 Characters')
            .test('checkMin', `${phrase.invalid_format} ${phrase.zip}`, (value) => {
                let zip = parseInt(value)
                if (zip < 0) return false
                return true
            }),
        street_address: Yup.string().required(`${phrase.enter} ${phrase.street_address}`),
    })
}
export const step2Data = (formik, phrase, allCountries, statesinLocal) => {
    return [
        {
            label: phrase.street_address,
            name: 'street_address',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.street_address}`,
            class: 'col-md-6 col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: phrase.country,
            placeholder: `${phrase.select} ${phrase.country}`,
            class: 'col-md-6 col-12',
            type: 'select',
            options: allCountries,
            name: 'counntry',
            formik: formik,
        },
        {
            label: phrase.state,
            placeholder: `${phrase.select} ${phrase.state}`,
            class: 'col-md-6 col-12',
            type: 'select',
            options: statesinLocal,
            name: 'state',
            formik: formik,
        },
        {
            label: phrase.city,
            name: 'city',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.city}`,
            class: 'col-md-3 col-12',
            formik: formik,
        },
        {
            label: phrase.zip,
            name: 'zip_code',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.zip}`,
            class: 'col-md-3 col-12',
            formik: formik,
            inputProps: { min: 0 },
        },
    ]
}

export const step3InitialValues = () => {
    return {
        categories: '',
    }
}

export const step3ValidationSchema = (phrase) => {
    return Yup.object({
        categories: Yup.string().required(`${phrase.select} ${phrase.category}`),
    })
}
export const step4InitialValues = () => {
    return {
        agree: [],
    }
}

export const step4ValidationSchema = (phrase) => {
    return Yup.object({
        agree: Yup.string().required('Required'),
    })
}
