import React, { useEffect, useState, useContext } from 'react'
import CommonContext from '../../context/common/commonContext'
import LoaderContext from '../../context/loaders/loader.context'
import ProductContext from '../../context/product/productContext'
import { Link } from 'react-router-dom'
import './Sitemap.css'
import Loader from '../../components/molecules/Loader'

const Sitemap = () => {
    let [pageData, setPageData] = useState('')
    let { getSitemap, staticPages, language, getLangPhrase, phrase } = useContext(CommonContext)
    let { setStaticPageload, isStaticPageLoad } = useContext(LoaderContext)
    const { dispatchSelectedCategories } = useContext(ProductContext)

    useEffect(async () => {
        setStaticPageload(true)
        getLangPhrase(language)
        document.title = global.site_title + ' | Site Map'
        await getSitemap()
    }, [language])
    useEffect(() => {
        setStaticPageload(false)
        setPageData(staticPages)
    }, [staticPages])

    const categoryClickHandler = (category) => {
        let selectedCatPayload = []
        selectedCatPayload.push({
            id: 'category' + category.id,
            name: category.name,
            type: 'category',
            filterBy: category.id,
        })
        dispatchSelectedCategories(selectedCatPayload)
    }

    const condtionClickHandler = (condition) => {
        let selectedConPayload = []
        selectedConPayload.push({
            id: 'itemcondition' + condition.id,
            name: condition.condition,
            type: 'condition',
            filterBy: condition.id,
        })
        dispatchSelectedCategories(selectedConPayload)
    }

    const listingClickHandler = (listingType, id) => {
        let selectedListPayload = []
        selectedListPayload.push({
            id: 'auctiontype' + id,
            name: listingType,
            type: 'listing',
            filterBy: listingType,
        })
        dispatchSelectedCategories(selectedListPayload)
    }

    const regionClickHandler = (region) => {
        let selectedRegionPayload = []
        selectedRegionPayload.push({
            id: 'regionlist' + region.regionid,
            name: region.region_eng,
            type: 'region',
            filterBy: region.regionid,
        })
        dispatchSelectedCategories(selectedRegionPayload)
    }

    const lotsizeClickHandler = (lotsize, id) => {
        let selectedSizePayload = []
        selectedSizePayload.push({
            id: 'lottype' + id,
            name: lotsize,
            type: 'lot',
            filterBy: lotsize,
        })
        dispatchSelectedCategories(selectedSizePayload)
    }

    return (
        <>
            {Object.keys(pageData).length !== 0 ? (
                <div className="container-md">
                    <div className="siteMapcontainer">
                        <div>
                            <h1 className="heading">{phrase.site_map}</h1>
                            <h4 className="sub-heading">{phrase.all_items}</h4>
                        </div>
                        <div className="sitemap_section row">
                            <div className="col-sm-12">
                                <h3 className="section-heading">{phrase.categories}</h3>
                            </div>
                            {pageData.categories.map((category) =>
                                pageData.phrase[category.language_var] !== undefined ? (
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                        <Link
                                            to="/search"
                                            onClick={() => categoryClickHandler(category)}
                                        >
                                            {phrase[category.language_var]}
                                        </Link>
                                    </div>
                                ) : (
                                    ''
                                ),
                            )}
                        </div>
                        <div className="sitemap_section row">
                            <div className="col-sm-12">
                                <h3 className="section-heading">{phrase.condition}</h3>
                            </div>
                            {pageData.condition.map((condition) =>
                                pageData._phrase[condition.language_var] !== undefined ? (
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                        <Link
                                            to="/search"
                                            onClick={() => condtionClickHandler(condition)}
                                        >
                                            {phrase[condition.language_var]}
                                        </Link>
                                    </div>
                                ) : (
                                    ''
                                ),
                            )}
                        </div>
                        <div className="sitemap_section row">
                            <div className="col-sm-12">
                                <h3 className="section-heading">{phrase.listing_format}</h3>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <Link to="/search" onClick={() => listingClickHandler('live', 3)}>
                                    {phrase.live_auction}
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <Link to="/search" onClick={() => listingClickHandler('buynow', 1)}>
                                    {phrase.buy_it_now}
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <Link to="/search" onClick={() => listingClickHandler('sealed', 0)}>
                                    {phrase.blind_auction}
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <Link
                                    to="/search"
                                    onClick={() => listingClickHandler('pricelock', 2)}
                                >
                                    {phrase.price_lock}
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <Link
                                    to="/search"
                                    onClick={() => listingClickHandler('lineitem', 4)}
                                >
                                    Lineitem
                                </Link>
                            </div>
                        </div>
                        <div className="sitemap_section row">
                            <div className="col-sm-12">
                                <h3 className="section-heading">{phrase.regions}</h3>
                            </div>

                            {pageData.regions.map((region) =>
                                pageData._phrase[region.language_var] !== undefined ? (
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                        <Link
                                            to="/search"
                                            onClick={() => regionClickHandler(region)}
                                        >
                                            {phrase[region.language_var]}
                                        </Link>
                                    </div>
                                ) : (
                                    ''
                                ),
                            )}
                        </div>
                        <div className="sitemap_section row">
                            <div className="col-sm-12">
                                <h3 className="section-heading">{phrase.lot_size}</h3>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <Link to="/search" onClick={() => lotsizeClickHandler('small', 1)}>
                                    {phrase.small}
                                </Link>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <Link to="/search" onClick={() => lotsizeClickHandler('large', 0)}>
                                    {phrase.large}
                                </Link>
                            </div>
                        </div>
                        <div className="sitemap_section row">
                            <div className="col-sm-12">
                                <h3 className="section-heading">{phrase.legal}</h3>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <a
                                    href="https://www.procurri.com/about-procurri/ "
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {phrase.about}
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <a href="/support">{phrase.support}</a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <a href="/sitemap">{phrase.site_map}</a>
                            </div>
                        </div>
                    </div>
                </div>
            ) : isStaticPageLoad ? (
                <Loader />
            ) : (
                'No Data'
            )}
        </>
    )
}

export default Sitemap
